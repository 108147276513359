<template lang="pug">
.home-but-box
  .exit(@click="exit") 
    img(src="img/exit.svg", alt="")
    span Выход
  .home-but(@click="goHome") 
    img(src="img/people.svg", alt="")
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setLokalDefault } from "@/components/function/distrobject";
export default {
  name: "mome-but",
  setup() {
    const store = useStore();
    const router = useRouter();
    function exit() {
      document.cookie = `m-quiz-auth=; max-age=-1`;
      store.commit("CLEAR_STATE");
      setLokalDefault();
      router.push({ name: "login" });
    }
    function goHome() {
      router.push({ name: "home" });
    }
    return {
      goHome,
      exit,
    };
  },
};
</script>

<style lang="sass" scoped>
.home-but-box
  display: flex
  align-items: center
  position: absolute
  top: 0px
  right: 8px
  padding: 5px 15px 15px 15px
  box-sizing: border-box
  z-index: 1
  .exit
    display: flex
    align-items: center
    cursor: pointer
    img
      margin-right: 8px
      transition: .3s
    span
      font-weight: 600
      color: #494949
      font-size: 17px
    &:hover img
      transform: translateX(3px)
  .home-but
    margin-left: 20px
    transition: .3s
    &:hover
      transform: scale(.95)
      cursor: pointer
</style>