import Checkbox from "@/components/quiz/Checkbox";
import CheckboxImg from "@/components/quiz/CheckboxImg";
import RadioImg from "@/components/quiz/RadioImg";
import Radio from "@/components/quiz/Radio";
import Range from "@/components/quiz/Range";
import RangeCheck from "@/components/quiz/RangeCheck";
import InputText from "@/components/quiz/InputText";
import TextCheck from "@/components/quiz/TextCheck";
import LastForm from "@/components/quiz/LastForm";
export default[
  Checkbox,
  CheckboxImg,
  RadioImg,
  Radio,
  Range,
  RangeCheck,
  InputText,
  TextCheck,
  LastForm,
]