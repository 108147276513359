<template lang="pug">
.reply-box.textCheck(:class="{ 'reply-box-select': seletPageAll }")
  .reply-box__reply
    input(
      type="text",
      name="placeholderText",
      placeholder="Подсказка",
      :value="name.placeholder",
      @input="setPlaceholder($event, idx)"
    )

    label
      .reply-check
      input(
        type="text",
        name="checkboxText",
        placeholder="Любой текст",
        :value="name.reply[0].replytext",
        @input="setQuestion($event, idx)"
      )
    .reply-nav-box
      input(
        type="number",
        name="gopage",
        title="На какую страницу ведет",
        :value="name.reply[0].gopage",
        @input="noNegativeNumber($event, idx)"
      )
</template>

<script>
export default {
  name: "text-check",
  props: [
    "name",
    "idx",
    "setPlaceholder",
    "noNegativeNumber",
    "setQuestion",
    "seletPageAll",
  ],
  setup() {},
};
</script>

<style lang="sass">
.textCheck
  .reply-box__reply
    width: 100%
    border-radius: 4px
    position: relative
    margin-bottom: 5px
    label
      position: relative
      margin-bottom: 0
      [name="checkboxText"]
        margin-top: 10px
        padding-left: 35px
      .reply-check
        width: 30px
        height: 30px
        background: #FFFFFF
        box-sizing: border-box
        border-radius: 4px
        position: absolute
        left: 0px
        top: 10px
        border: 1px solid #cacaca
        &::before,&::after
          content: ""
          height: 2px
          background: #000
          width: 10px
          position: absolute
          left: 0
          right: 8px
          top: 1.5px
          bottom: 0
          margin: auto
          transform: rotate(45deg)
        &::after
          right: -8px
          top: -2px
          width: 15px
          transform: rotate(-45deg)
    .reply-nav-box
      margin-top: 5px
      input
        max-width: 60px
</style>