<template lang="pug">
section.color-block
  h3 Выберите цвет
  .row
    .col-sm-4.col-md-4.col-lg-4
      label.color 
        p Цвет кнопок
        input(v-model="color.butColor", name="butColor", type="color")
      label.color 
        p Цвет текста кнопок
        input(v-model="color.butColorText", name="butColorText", type="color")
      label.color 
        p Цвет фона
        input(v-model="color.overlay", name="overlay", type="color")
    .col-sm-12.col-md-6.col-lg-6
      .maket-overley(:style="{ background: color.overlay }")
        .maket-body
          .maket-check(:style="{ background: color.butColor }")
            span(:style="{ color: color.butColorText }") &#10004;
          .maket-nav-box 
            .maket-line(:style="{ background: color.butColor }")
            .maket-but(
              :style="{ color: color.butColorText, background: color.butColor }"
            ) Далее
Modal(:colorParams="color")
</template>

<script>
import Modal from "@/components/settings/Modal";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    Modal,
  },
  setup() {
    const store = useStore();
    const color = ref({
      butColor: "#ff0000",
      butColorText: "#ffffff",
      overlay: "#ff0000",
    });
    watch(
      () => color.value,
      (e) => {
        localStorage.setItem("color", JSON.stringify(color.value));
      },
      { deep: true }
    );
    function getLocal(key) {
      return JSON.parse(localStorage.getItem(key));
    }
    watch(
      () => store.state.allQuiz,
      () => {
        color.value = getLocal("color");
      }
    );
    onMounted(() => {
      if (!localStorage.getItem("color")) {
        localStorage.setItem("color", JSON.stringify(color.value));
      } else {
        color.value = getLocal("color");
      }
    });
    return {
      color,
    };
  },
};
</script>

<style lang="sass" scoped>
.maket-overley
  width: 240px
  height: 130px
  background: red
  display: flex
  justify-content: center
  align-items: center
  .maket-body
    width: 220px
    height: 110px
    background: #fff
    .maket-check
      width: 25px
      height: 25px
      background: red
      display: flex
      justify-content: center
      align-items: center
      margin: 20px
      border-radius: 2px
    .maket-nav-box
      display: flex
      justify-content: space-around
      align-items: center
      .maket-line
        width: 60%
        height: 4px
        background: red
      .maket-but
        padding: 5px
        background: red
        color: #fff
        font-size: 14px
        border-radius: 2px
</style>