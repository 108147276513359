<template lang="pug">
.reply-box.check_img(:class="{ 'reply-box-select': seletPageAll }")
  .reply-box__reply(
    v-for="(reply, replayID) in name",
    :id="replayID",
    draggable="true"
  )
    .reply-check
    label.fileReply
      .fileReply-img-cap
      template(v-if="CHECK_IMG[idx].reply[replayID].img")
        img(:src="CHECK_IMG[idx].reply[replayID].img")
      template(v-if="!CHECK_IMG[idx].reply[replayID].img")
        img(src="@/../public/img/img-file.svg")
      input(
        @input="getFileInput($event, idx, replayID)",
        type="file",
        name="replyFile",
        accept=".png,.jpg,.jpeg,.gif,.svg"
      )

    textarea(
      type="text",
      name="replytext",
      placeholder="Вариант ответа",
      :value="reply.replytext",
      @input="setQuestion($event, idx, replayID)"
    )
    .reply-nav-box
      input(
        type="number",
        name="gopage",
        title="На какую страницу ведет",
        :value="reply.gopage",
        @input="noNegativeNumber($event, idx, replayID)"
      )
      .delReply(@click="delReply($event, idx, replayID)") &#10006;
      template(v-if="reply.addbut === true")
        .reply-add-page(@click="addReply($event, idx, replayID)")
</template>

<script>
export default {
  name: "checkbox-img",
  props: [
    "name",
    "idx",
    "addReply",
    "noNegativeNumber",
    "setQuestion",
    "delReply",
    "seletPageAll",
    "getFileInput",
    "CHECK_IMG",
  ],
  setup() {},
};
</script>

<style lang="sass" scoped>
.check_img
  .reply-box__reply
    padding: 5px
    width: 186px
    border: 1px solid #f5f5f5
    border-radius: 4px
    position: relative
    margin-right: 5px
    margin-bottom: 5px
    .reply-check
      width: 30px
      height: 30px
      background: #FFFFFF
      box-sizing: border-box
      border-radius: 4px
      position: absolute
      right: 8px
      top: 8px
      border: 1px solid #cacaca
      &::before,&::after
        content: ""
        height: 2px
        background: #000
        width: 10px
        position: absolute
        left: 0
        right: 8px
        top: 1.5px
        bottom: 0
        margin: auto
        transform: rotate(45deg)
      &::after
        right: -8px
        top: -2px
        width: 15px
        transform: rotate(-45deg)
    textarea
      width: 100%
      height: 70px
      padding-right: 30px
    .reply-nav-box
      display: flex
      align-items: center
      justify-content: space-between
      position: relative
      input
        max-width: 60px
    label
      background: #999
      display: flex
      justify-content: center
      align-items: center
      margin-bottom: 8px
      cursor: pointer
      height: 110px
      overflow: hidden
      [name="replyFile"]
        display: none
      .fileReply-img-cap
        position: absolute
        z-index: 2
        width: 175px
        height: 110px
</style>