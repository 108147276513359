<template lang="pug">
.createUser
  .form
    template(v-if="login == 'register'")
      register-form(@pageChange="pageChange")
    template(v-if="login == 'login'")
      login-form(@pageChange="pageChange")
    template(v-if="login == 'recovery'")
      recovery-password(@pageChange="pageChange")
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    let store = useStore();
    let login = ref("login");

    function pageChange(event) {
      login.value = event;
    }
    return {
      login,
      pageChange,
      store,
    };
  },
};
</script>

<style lang="sass">
.component-fade-enter-active,
.component-fade-leave-active
  transition: opacity 0.3s ease

.component-fade-enter-from,
.component-fade-leave-to
  opacity: 0

.createUser
  width: 100%
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  .registerPage
    cursor: pointer
    font-weight: 600
    font-size: 18px
  .form
    max-width: 480px
    width: 100%
    max-height: 450px
    background: #fff
    border-radius: 10px
    box-shadow: 0 10px 40px 10px rgba(0, 0, 0, .3)
    padding: 25px 25px 45px
    .createUser-header
      h2
        text-align: center
      .auth__steps
        display: flex
        justify-content: space-between
    .auth__page
      display: flex
      align-items: center
      flex-direction: column
      justify-content: space-between
      input
        width: 300px
        height: 50px
        outline: none
        padding: 0 10px
        margin: 7px 0
        border: 1px solid #c2c2c2
        box-sizing: border-box
        border-radius: 4px
    .but-box
      display: flex
      align-items: center
      justify-content: space-between
      #prev
        background: #999
        max-width: 80px
        margin-right: 10px
</style>