<template lang="pug">
h1 Аналитика - {{ siteName }}
.row.gy-3
  .col-sm-12.col-md-4.col-lg-3
    input#el(type="hidden", readonly)
  .col-sm-12.col-md-4.col-lg-3
    .analitics-item-box
      p Количество открытий
      .item-box
        span {{ analyticsSum.sumClick }}
  .col-sm-12.col-md-4.col-lg-3
    .analitics-item-box
      p Количество заявок
      .item-box
        span {{ analyticsSum.sumSend }}
  .col-sm-12.col-md-4.col-lg-3
    .analitics-item-box
      p Конверсия
      .item-box
        span(v-if="analyticsSum.sumClick == 0") {{ (0).toFixed(2) }}%
        span(v-if="analyticsSum.sumClick !== 0") {{ ((analyticsSum.sumSend / analyticsSum.sumClick) * 100).toFixed(2) }}%
  template(v-for="(item, i) in analytics", :key="'a' + i")
    .col-sm-12.col-md-4.col-lg-3
      .analitics-item-box
        .item-box.item-mini
          span {{ monthName(item.month) }} - {{ item.year }}
    .col-sm-12.col-md-4.col-lg-3
      .analitics-item-box
        .item-box.item-mini
          span {{ item.openquiz }}
    .col-sm-12.col-md-4.col-lg-3
      .analitics-item-box
        .item-box.item-mini
          span {{ item.applications }}
    .col-sm-12.col-md-4.col-lg-3
      .analitics-item-box
        .item-box.item-mini
          span {{ ((item.applications / item.openquiz) * 100).toFixed(2) }}%

//- canvas#chart
</template>

<script>
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import { useStore } from "vuex";
import { ref, onMounted, watch, computed } from "vue";
import { monthName } from "@/components/function/distrobject";
export default {
  setup() {
    const store = useStore();
    let analyticsSum = ref({ sumClick: 0, sumSend: 0 });
    let addcalendar = "";
    const siteName = computed(() => {
      return store.state.siteName;
    });
    const analytics = computed(() => {
      return store.state.analytics;
    });
    const date = computed(() => {
      return store.state.date;
    });
    onMounted(() => {
      const clearCalendar = document.querySelectorAll(".air-datepicker");
      if (clearCalendar) clearCalendar.forEach((e) => e.remove());
      new Promise((r) => r(calendar())).then(() => updateCalendar());
      if (analytics.value.length == 0 && siteName.value)
        store.dispatch("getAnalyst");
      calcAnalytics(analytics.value);
    });
    function calendar() {
      let butSeeCalendar = {
        content: "Показать",
        onClick: (dp) => {
          calendarSend(dp.$el.value);
        },
      };
      let butClearCalendar = {
        content: "Очистить",
        onClick: () => {
          addcalendar.clear();
          store.commit("UPDATE_ANALYST", "");
        },
      };
      addcalendar = new AirDatepicker("#el", {
        inline: true,
        view: "months",
        minView: "months",
        dateFormat: "MM.yyyy",
        multipleDatesSeparator: " - ",
        buttons: [butSeeCalendar, butClearCalendar],
        multipleDates: true,
      });
    }

    function updateCalendar() {
      addcalendar.update({
        selectedDates: [new Date()],
        onRenderCell({ date, type }) {
          const dateStore = new Date(store.state.date * 1000);
          const month = +dateStore.getMonth() - 1;
          const year = dateStore.getFullYear();
          if (type === "month") {
            if (date.getMonth() <= month && date.getFullYear() <= year) {
              return {
                disabled: true,
              };
            }
          }
        },
      });
    }

    watch(
      () => date.value,
      () => {
        updateCalendar();
      }
    );
    watch(
      () => siteName.value,
      (e) => {
        if (addcalendar) addcalendar.destroy();
        new Promise((r) => r(calendar())).then(() => updateCalendar());
        calcAnalytics(analytics.value);
        if (e) store.dispatch("getAnalyst");
      }
    );
    watch(
      () => analytics.value,
      (e) => {
        calcAnalytics(e);
      }
    );
    function calcAnalytics(params) {
      let sumClick = 0;
      let sumSend = 0;
      for (const iterator of params) {
        sumClick += +iterator.openquiz;
        sumSend += +iterator.applications;
      }
      analyticsSum.value = { sumClick: sumClick, sumSend: sumSend };
    }
    // function chart(params) {
    //   const canvas = document.getElementById("chart");
    //   const ctx = canvas.getContext("2d");
    //   ctx.fillStyle = "rgb(200, 0, 0)";
    //   ctx.fillRect(10, 0, 50, 50);
    // }
    function calendarSend(val) {
      const arr = val.replace(/\s/g, "").split("-");
      let months = new Set();
      let years = new Set();
      let data = {
        months: [],
        years: [],
      };
      for (const iterator of arr) {
        months.add(iterator.split(".")[0]);
        years.add(iterator.split(".")[1]);
      }
      data.months = [...months];
      data.years = [...years];
      store.dispatch("getAnalyst", data);
    }

    return {
      date,
      siteName,
      analytics,
      // getAnalyst,
      calendarSend,
      monthName,
      analyticsSum,
      calendar,
    };
  },
};
</script>

<style lang="sass">
canvas
  width: 100%
  height: 500px
  background: #e9e9e9
  margin-top: 20px
.analitics-item-box
  border: 1px solid #CECECE
  box-sizing: border-box
  border-radius: 5px
  p
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 23px
    text-align: center
    color: #000000
    margin: 10px 0
  .item-box
    background: #efefef
    border-radius: 0 0 5px 5px
    border-top: 1px solid #CECECE
    height: 215px
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

    span
      font-style: normal
      font-weight: bold
      font-size: 36px
      line-height: 47px
      text-align: center
      color: #000000
  .item-mini
    height: 40px
    border-top: none
    border-radius: 5px
    span
      font-style: normal
      font-weight: 600
      font-size: 18px
      line-height: 47px
      text-align: center
      color: #000000
.air-datepicker
  width: 100%
.air-datepicker-cell.-selected-.-current-
  background: #FF6C52 !important
  color: #fff !important
.air-datepicker-cell.-selected-
  background: #FF6C52 !important
.air-datepicker-cell.-current-
  color: #FF6C52 !important
.air-datepicker-button
  color: #FF6C52 !important
</style>