<template lang="pug">
.quiz-page-container.last-page
  .quiz-page
    .num-gage {{ idx }}
    .avatar
      .maket-modal-avatar
        img(:src="avatarImg")
      textarea.avatar-text(
        type="text",
        name="avatartext",
        placeholder="Текст подсказка",
        v-model="lastPage.avatarText"
      )
    input.question-text(
      type="text",
      name="question",
      placeholder="Текст",
      v-model="lastPage.question"
    )
    .rama-quiz__question
      .rama-quiz__last-page
        .input-box-flex
          input(
            name="select",
            type="text",
            placeholder="Выберите поле",
            value="",
            @click="toggleList($event)",
            readonly
          )
          ul
            template(v-for="item in selectInfo", :key="item.id")
              li(
                @click="selectLi",
                v-if="item.flag",
                :data-type="item.type",
                :data-placeholder="item.placeholder"
              ) {{ item.name }}
          .input-plus
        .input-box
          transition-group(name="list", tag="span")
            .input(
              v-for="(input, i) in lastPage.inputs",
              :key="i",
              :id="i",
              draggable="true",
              @drop="onDrop($event)",
              @dragover="dragOver($event)",
              @dragstart="onDragStart($event)",
              @dragend="dragEnd($event)"
            )
              span {{ input.name }}
              input(
                :key="i",
                :name="input.name",
                :type="input.type",
                :placeholder="input.placeholder",
                @input="getValue($event, i)"
              )
              .remove-input(@click="removeInput(i, input.name)") &#10006;
          input.button.rama-quiz__button-send(
            type="text",
            v-model="lastPage.buttonTitle",
            name="text"
          )

      input.lastText(
        type="text",
        name="question",
        placeholder="Вопрос",
        v-model="lastPage.lastText"
      ) 
</template>

<script>
import { ref, watch, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { getLocal } from "@/components/function/distrobject";
export default {
  props: ["idx", "avatarImg"],
  name: "last-form",
  setup(props) {
    const store = useStore();
    const lastPage = ref({
      page: "",
      form: "lastpage-form",
      avatarText: "",
      question:
        "Оставьте телефон - мы закрепим бонус за вашим номером, а также предоставим расчет полной стоимости проекта!",
      lastText:
        "*Наши специалисты с удовольствием ответят на все вопросы и помогут правильно сделать выбор.",
      buttonTitle: "Отправить",
      inputs: [
        {
          type: "text",
          name: "Имя",
          placeholder: "Введите Ваше имя",
          flag: true,
        },
        {
          type: "text",
          name: "Телефон",
          placeholder: "Введите Ваш телефон",
          flag: true,
        },
      ],
    });
    let selectInfo = ref([
      {
        id: "1",
        type: "text",
        name: "Имя",
        placeholder: "Введите Ваше имя",
        flag: true,
      },
      {
        id: "2",
        type: "text",
        name: "Телефон",
        placeholder: "Введите Ваш телефон",
        flag: true,
      },
      {
        id: "3",
        type: "text",
        name: "Выбор связи",
        placeholder: "Выбрать способ связи",
        flag: true,
      },
      {
        id: "4",
        type: "email",
        name: "Email",
        placeholder: "Введите Ваш Email",
        flag: true,
      },
      {
        id: "5",
        type: "text",
        name: "Текстовое поле",
        placeholder: "Введите текст",
        flag: true,
      },
      {
        id: "6",
        type: "number",
        name: "Только цифры",
        placeholder: "",
        flag: true,
      },
    ]);
    function onDragStart(e) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.dropAllowed = "move";
      e.dataTransfer.setData("itemId", e.target.id.toString());
      e.target.classList.add(`elem-selected`);
    }
    function dragEnd(e) {
      e.target.classList.remove(`elem-selected`);
      document.querySelectorAll(".input input").forEach((e) => {
        e.value = "";
      });
      document.querySelectorAll(".input").forEach((e) => {
        e.classList.remove("dropDownColor");
      });
    }

    function dragOver(e) {
      e.preventDefault();
      if (!e.currentTarget.classList.contains("elem-selected")) {
        e.currentTarget.classList.add("dropDownColor");
        document.querySelectorAll(".dropDownColor").forEach((el) => {
          if (el !== e.currentTarget.closest(".input"))
            el.classList.remove("dropDownColor");
        });
      }
    }

    function onDrop(e) {
      const itemId = parseInt(e.dataTransfer.getData("itemId")); // ID Элемент который схватил
      const ID = parseInt(e.target.closest(".input").id); // ID Элемент который меняем
      const allEl = lastPage.value.inputs[itemId]; // ВЕСЬ Элемент который схватил
      const allElArr = lastPage.value.inputs[ID]; // ВЕСЬ элемент который меняем
      lastPage.value.inputs.splice(ID, 1, allEl);
      lastPage.value.inputs.splice(itemId, 1, allElArr);
    }
    watch(
      () => props.idx,
      (e) => {
        lastPage.value.page = e;
      }
    );
    watch(
      () => store.state.allQuiz,
      () => {
        lastPage.value = getLocal("lastPage");
      }
    );
    watch(
      () => lastPage.value,
      (e) => {
        localStorage.setItem("lastPage", JSON.stringify(e));
      },
      { deep: true }
    );
    onMounted(() => {
      if (localStorage.getItem("lastPage")) {
        lastPage.value = getLocal("lastPage");
      } else {
        localStorage.setItem("lastPage", JSON.stringify(lastPage.value));
      }
      lastPage.value.inputs.forEach((e, i) => {
        selectInfo.value.forEach((el) => {
          if (e.name == el.name) el.flag = false;
        });
      });
    });
    function getValue(e, i) {
      lastPage.value.inputs[i].placeholder = e.target.value;
    }
    function toggleList(e) {
      document.querySelectorAll(".openUl").forEach((x) => {
        if (e.target.nextElementSibling == x) return;
        x.classList.remove("openUl");
      });
      e.target.nextElementSibling.classList.toggle("openUl");
    }
    function selectLi(e) {
      const el = e.target.parentElement.previousSibling;
      const selectText = e.target.textContent;
      const selectData = e.target.getAttribute("data-type");
      const selectPl = e.target.getAttribute("data-placeholder");
      el.value = selectText;
      e.target.parentElement.classList.remove("openUl");
      addInput(selectData, selectText, selectPl);
      el.value = "";
    }
    function addInput(t, n, p) {
      selectInfo.value.forEach((el, i) => {
        if (el.name == n) el.flag = false;
      });
      let obj = {
        type: t,
        name: n,
        placeholder: p,
      };
      lastPage.value.inputs.push(obj);
    }
    function removeInput(i, name) {
      selectInfo.value.forEach((el) => {
        if (el.name == name) el.flag = true;
      });
      lastPage.value.inputs.splice(i, 1);
    }
    return {
      selectInfo,
      toggleList,
      selectLi,
      onDragStart,
      dragEnd,
      onDrop,
      dragOver,
      getValue,
      lastPage,
      removeInput,
    };
  },
};
</script>

<style lang="sass" scoped>
.last-page
  .quiz-page
    cursor: auto
  .elem-selected
    opacity: .2
  .dropDownColor
    background: #E0E0E0
  .question-text,.lastText
    text-align: center
  .input-box
    margin: auto
    max-width: 350px
    padding: 15px
    .input
      display: flex
      align-items: center
      position: relative
      cursor: move
      padding: 10px
      margin-bottom: 8px
      span
        position: absolute
        top: 2px
        background: #fff
        font-size: 14px
        color: #999
        padding: 0 3px

      .remove-input
        position: relative
        font-size: 20px
        color: #ff0000
        box-sizing: border-box
        border-radius: 4px
        cursor: pointer
        margin-left: 10px
        margin-bottom: 10px
    input
      padding: 15px 10px
    .button
      margin: 10px auto 0
      display: block
      background: #626262
      text-align: center
      color: #fff
      max-width: 250px
      border-radius: 6px
  .input-box-flex
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 140px
    z-index: 1

    input
      background: none
      cursor: pointer
    .input-plus
      min-width: 30px
      height: 30px
      position: relative
      background: #C4C4C4
      box-sizing: border-box
      border-radius: 0px 4px 4px 0px
      cursor: pointer
      margin-left: 10px
      position: absolute
      right: 0
      z-index: -1
      cursor: pointer
      &::before,&::after
        content: ""
        height: 1px
        background: #000
        width: 15px
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        margin: auto
      &::after
        transform: rotate(90deg)
    ul
      padding: 0
      display: inline-block
      background: #fff
      margin-top: 0
      display: none
      position: absolute
      z-index: 1
      top: 30px
      li
        list-style: none
        padding: 10px 15px
        margin: 0
        transition: .3s
        cursor: pointer
        &:hover
          background: #FF6C52
          color: #fff
    .openUl
      animation: upList .3s linear
      display: block
      box-shadow: 0px 2px 5px rgba(0, 0, 0, .25)
    .input-type
      padding: 5px
      font-size: 14px
      color: #a7a7a7
      width: 100%
      cursor: pointer
      border-color: #d4d4d4
      border-radius: 4px

.list-item
  display: inline-block
  margin-right: 10px

.list-enter-active,
.list-leave-active
  transition: all .4s ease

.list-enter-from,
.list-leave-to
  opacity: 0
  transform: translateY(30px)
@keyframes upList
  0%
    transform: translateY(10px)
    opacity: 0
  100%
    transform: translateY(0px)
    opacity: 1
</style>