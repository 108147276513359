<template lang="pug">
.main-page
  .header 
    .container 
      .menu
        .logo 
          img(src="@/../public/img/main-page/logo.svg", alt="")
        .phone-box
          a.phone(href="tel:+375291050055") +375 29 105-00-55
          a.mail(href="mailto:MediaRama-info@yandex.by") MediaRama-info@yandex.by
    .container(style="padding-top: 70px; padding-bottom: 70px")
      .row.gy-4.align-items-center
        .col-sm-12.col-md-6.col-lg-7
          h1 RamaQuiz
          h2 Квиз для сайта, приводящий клиентов
          button.button.r-quiz Попробовать бесплатно
        .col-sm-12.col-md-6.col-lg-5.justify-content-center.justify-content-sm-center.justify-content-md-end.justify-content-lg-end.d-flex.d-sm-flex
          .fullscreen-bg__video
            video(loop, muted, autoplay)
              source(
                src="@/../public/img/main-page/animate.mp4",
                type="video/mp4"
              )
.block-1
  .container 
    .title
      h2 Квиз на сайте — это опрос для сбора заявок
      p Простая идея, которая приносит реальную пользу бизнесу. <br>Если реализована правильно. Как !{ '' }
        b RamaQuiz.
    .row.gy-4
      .col-sm-12.col-md-12.col-lg-5.d-flex.justify-content-end
        .item
          ul
            li Вы знаете, чего хочет клиент еще до знакомства с ним
            li У вас есть время приготовиться к презентации и учесть потребности клиента
            li Клиент уже мотивирован принять ваше предложение
      .col-sm-12.col-md-12.col-lg-2.d-flex.justify-content-center
        .item2
          span =
      .col-sm-12.col-md-12.col-lg-5.d-flex.justify-content-start
        .item
          .p
            p.h RamaQuiz
            p.l повышает качество заявок
.block-2
  .container 
    .title
      h2 Не конструктор, а решение для бизнеса
    .row.gy-4
      .col-sm-12.col-md-12.col-lg-6.d-flex.justify-content-center
        .item
          p.t-text Как это работает у конкурентов
          p.c-text Другие квизы — это конструктор, через который вы создаете опрос, который размещаете на сайте. Технически это не сложно, но нет никаких гарантий, что такой опрос приведет клиентов.
          p.b-text А деньги вы уже потратите
      .col-sm-12.col-md-12.col-lg-6.d-flex.justify-content-center
        .item.item2
          p.t-text 
            b RamaQuiz !{ " " }
            | составляется профессионалами персонально под ваш бизнес
          p.c-text Наш специалист позвонит вам, задаст нужные вопросы и предложит лучшее решение
.block-3
  .container 
    .title
      h2 Каждому вопросу — подходящий формат
      p Квиз подстраивается под любой вопрос.
      p Грамотный выбор форматов моментально доносит смысл вопроса, <br>а разнообразие шаблонов помогает не утомлять клиентов и мотивирует пройти опрос до конца.
    .row.gy-4
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/1.svg", alt="")
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/2.svg", alt="")
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/3.svg", alt="")
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/4.svg", alt="")
.block-4
  .container 
    .title
      h2 
        b RamaQuiz !{ "" }
        | адаптируется под корпоративный стиль<br> и встраивается в дизайн сайта
    .row.gy-4
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/m-1.svg", alt="")
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/m-2.svg", alt="")
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/m-3.svg", alt="")
      .col-6.col-sm-6.col-md-6.col-lg-3
        .item
          img(src="@/../public/img/main-page/m-4.svg", alt="")
.block-5
  .container 
    .title
      h2 Подойдет ли он вам?
      p Наш квиз помогает как индивидуальным предпринимателям, так и крупным бизнесам
    .row.gy-4
      .col-6.col-sm-6.col-md-6.col-lg-3(
        v-for="(item, i) in dataResult",
        :key="i"
      )
        .item
          .t-text {{ item.title }}
          .s-text.s-text2 Результаты за месяц
          .numb {{ item.numb1 }}
          .s-text заявку получили
          .numb {{ item.numb2 }}
          .s-text из них через !{ "" }
            b RamaQuiz
.block-6
  .container 
    .title
      h2 
        b RamaQuiz !{ "" }
        | приводит клиентов. Проверьте и примите решение
      p Тестовый период 30 дней
      button.button.r-quiz Попробовать бесплатно
.block-7
  .container
    .row
      .col-sm-12.col-md-12.col-lg-12
        a(
          href="https://rquiz.mediarama.by/dogovor.pdf",
          target="_blank",
          rel="noopener"
        ) Публичный договор возмездного оказания услуг
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const dataResult = [
      { title: "Заборы", numb1: "201", numb2: "155" },
      { title: "Кухни под заказ", numb1: "127", numb2: "32" },
      { title: "Турагентство", numb1: "676", numb2: "436" },
      { title: "Окна ПВХ", numb1: "144", numb2: "45" },
      { title: "Бурение скважин", numb1: "255", numb2: "96" },
      { title: "Механиз. штукатурка", numb1: "22", numb2: "10" },
      { title: "Натяжные потолки", numb1: "84", numb2: "27" },
      { title: "Мебель на заказ", numb1: "76", numb2: "30" },
    ];
    function body_script(src) {
      if (document.querySelector("script[src='" + src + "']")) {
        return;
      }
      let script = document.createElement("script");
      script.setAttribute("src", src);
      script.setAttribute("type", "text/javascript");
      document.body.appendChild(script);
    }
    onMounted(() => {
      body_script("https://rquiz.mediarama.by/?id=mediaramaby");
      body_script("https://ramabot.mediarama.by/?id=_rquizmediaramaby");
      const year = " 2022";
      const _0xe836 = [
        "\x32\x30\x32\x31",
        "\x62\x6F\x64\x79",
        "\x71\x75\x65\x72\x79\x53\x65\x6C\x65\x63\x74\x6F\x72",
        "\x64\x69\x76",
        "\x63\x72\x65\x61\x74\x65\x45\x6C\x65\x6D\x65\x6E\x74",
        "\x69\x6E\x6E\x65\x72\x48\x54\x4D\x4C",
        "\x3C\x61\x20\x73\x74\x79\x6C\x65\x3D\x22\x66\x6F\x6E\x74\x2D\x73\x69\x7A\x65\x3A\x20\x31\x34\x70\x78\x3B\x77\x69\x64\x74\x68\x3A\x20\x31\x30\x30\x25\x3B\x62\x61\x63\x6B\x67\x72\x6F\x75\x6E\x64\x3A\x20\x23\x34\x46\x34\x46\x34\x46\x3B\x64\x69\x73\x70\x6C\x61\x79\x3A\x20\x62\x6C\x6F\x63\x6B\x3B\x74\x65\x78\x74\x2D\x61\x6C\x69\x67\x6E\x3A\x20\x63\x65\x6E\x74\x65\x72\x3B\x70\x61\x64\x64\x69\x6E\x67\x3A\x20\x31\x30\x70\x78\x20\x30\x20",
        "\x77\x69\x64\x74\x68",
        "\x73\x63\x72\x65\x65\x6E",
        "",
        "\x38\x30\x70\x78",
        "\x3B\x63\x6F\x6C\x6F\x72\x3A\x20\x23\x66\x66\x66\x3B\x22\x68\x72\x65\x66\x3D\x22\x68\x74\x74\x70\x3A\x2F\x2F\x4D\x65\x64\x69\x61\x52\x61\x6D\x61\x2E\x62\x79\x22\x74\x61\x72\x67\x65\x74\x3D\x22\x5F\x62\x6C\x61\x6E\x6B\x22\x3E\u0420\u0410\u0417\u0420\u0410\u0411\u041E\u0422\u0410\u041D\x20\x2D\x20\x4D\x45\x44\x49\x41\x52\x41\x4D\x41\x2E\x42\x59\x20\xA9\x20",
        "\x3C\x2F\x61\x3E",
        "\x61\x70\x70\x65\x6E\x64",
      ];
      let bodyPod = document[_0xe836[2]](_0xe836[1]);
      let bodyhtml = document[_0xe836[4]](_0xe836[3]);
      bodyhtml[_0xe836[5]] = `${_0xe836[6]}${
        window[_0xe836[8]][_0xe836[7]] > 420 ? _0xe836[9] : _0xe836[10]
      }${_0xe836[11]}${year}${_0xe836[12]}`;
      bodyPod[_0xe836[13]](bodyhtml);
    });
    return { dataResult };
  },
};
</script>

<style lang="sass">
.block-7
  a
    text-align: center
    padding: 25px 15px
    color: #fff
    display: block
.block-6
  background: #F8F8F8
  .title
    p
      font-style: normal
      font-weight: 600
      font-size: 24px
      line-height: 28px
      color: #393939
.block-5
  background: #fff
  padding-bottom: 70px
  .title
    max-width: 100%
  .item
    background: #EBEBEB
    border-radius: 32px
    padding: 24px
    .t-text,.numb
      font-style: normal
      font-weight: 400
      font-size: 24px
      line-height: 28px
      color: #000000
    .numb
      margin-top: 20px
    .s-text
      font-style: normal
      font-weight: 400
      font-size: 16px
      line-height: 19px
      color: #000000
    .s-text2
      margin: 20px 0 0 0
.block-4
  padding-bottom: 70px
  background: #F8F8F8
  .title
    max-width: 100%
  .item
    img
      width: 100%
.block-3
  background: #fff
  padding-bottom: 70px
  .title
    max-width: 100%
  .item
    img
      width: 100%
.block-2
  padding-top: 10px
  padding-bottom: 70px
  background: #F8F8F8
  .title
    max-width: 100%
  h2
    text-align: center
    text-transform: uppercase
    font-style: normal
    font-weight: 500
    font-size: 36px
    line-height: 42px
    letter-spacing: 0.1em
    color: #393939
  .item
    padding: 15px 10px 15px 15px
    border: 1px solid #000000
    border-radius: 32px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .t-text,.c-text,.b-text
      font-style: normal
      font-weight: 600
      font-size: 24px
      line-height: 28px
      text-align: center
      color: #393939
      b
        color: #FF3D3D
    .c-text
      font-weight: 400
      font-size: 16px
      line-height: 19px
    .b-text
      font-weight: 400
      font-size: 20px
      line-height: 23px
      text-align: center
      letter-spacing: 0.1em
      text-transform: uppercase
  .item2
    background: #FFFFFF
    border: 1px solid #FF3D3D
    box-shadow: 10px 50px 50px rgba(255, 61, 61, 0.05), 5px 15px 30px rgba(255, 61, 61, 0.1), 2px 4px 10px rgba(0, 0, 0, 0.1)
.title
  padding-top: 40px
  padding-bottom: 40px
  margin: auto
  max-width: 600px
  h2
    font-style: normal
    font-weight: 600
    font-size: 28px
    line-height: 28px
    color: #000000
  p
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: normal
    color: #393939
  b
    font-weight: 900
    color: #FF3D3D
.block-1
  background: #fff
  padding-bottom: 70px

  ul
    li
      list-style: decimal
      font-style: normal
      font-weight: 400
      font-size: 20px
      line-height: normal
      color: #393939
      padding: 5px
  .item
    border-radius: 500px
    border: 1px solid #000
    height: 400px
    width: 400px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 30px
    @media screen and(max-width: 1399px)
      border-radius: 50px
      height: auto
      width: 100%
      padding: 20px
      min-height: 286px

  .item2
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    span
      font-size: 70px
      display: block
      @media screen and(max-width: 991px)
        transform: rotate(90deg)
  .p
    max-width: 200px
  .h,.l
    font-style: normal
    font-weight: 900
    font-size: 40px
    line-height: 47px
    color: #FF3D3D
    margin: 0
  .l
    color: #393939
    font-weight: 600

.main-page
  background: url(../../public/img/main-page/main.jpg) center top/cover no-repeat
  .menu
    display: flex
    justify-content: space-between
    align-items: center
    padding-top: 10px
    @media screen and(max-width: 490px)
      flex-wrap: wrap
      justify-content: center

  .phone-box
    @media screen and(max-width: 490px)
      margin-top: 10px
    a
      display: block
    .phone,.mail
      font-style: normal
      font-weight: 700
      font-size: 24px
      line-height: 28px
      text-align: right
      color: #6C6C6C
      text-decoration: none
    .mail
      font-weight: 400
      font-size: 16px
      line-height: 19px

  h1,h2
    padding: 0
    margin: 0
  h1
    font-style: normal
    font-weight: 900
    font-size: 96px
    line-height: 112px
    color: #FF3D3D
    @media screen and(max-width: 490px)
      font-size: 70px
      text-align: center

  h2
    font-style: normal
    font-weight: 600
    font-size: 36px
    line-height: 42px
    color: #393939
    @media screen and(max-width: 490px)
      text-align: center

  .fullscreen-bg__video
    max-width: 349px
    overflow: hidden
    border-radius: 15px
.button
  outline: none
  border: none
  background: #FF5353
  color: #fff
  position: relative
  padding: 15px
  max-width: 300px
  width: 100%
  text-transform: uppercase
  border-radius: 3px
  margin-top: 50px
  font-weight: 500
  font-size: 16px
  cursor: pointer
  overflow: hidden
  transition: .3s
  display: block
  @media screen and(max-width: 490px)
    margin: 50px auto 20px

  &:hover
    background: #ff3939
    transform: scale(.98)
  &::before
    content: ""
    width: 30px
    height: 150px
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5))
    position: absolute
    top: 0
    bottom: 0
    left: -60px
    margin: auto
    transform: rotate(10deg)
    animation: blic 3.5s linear infinite
@keyframes blic
  0%
    left: -80px
  25%
    left: 380px
  100%
    left: 380px
</style>