<template lang="pug">
h1 Клиенты - {{ siteName }}
.row.gy-2
  .col-sm-12.col-md-4.col-lg-3
    input#el2(type="hidden", readonly)
  .col-sm-12.col-md-4.col-lg-3
    .clientele-item-box
      p Количество клиетов
      .item-box
        span {{ clientele.length > 0 ? clientele.length : 0 }}
  .col-sm-12.col-md-4.col-lg-3
    .clientele-item-box
      p Скачать xlsx файл
      .item-box.item-button(
        @click="clientele.length > 0 ? saveXLSX($event) : ''"
      )
        svg(
          width="74",
          height="74",
          viewBox="0 0 74 74",
          fill="black",
          xmlns="http://www.w3.org/2000/svg"
        )
          path(
            d="M61.879 65.6601H12.1211C11.7203 65.6601 11.3953 65.3355 11.3953 64.9344V3.49589C11.3953 3.09482 11.7203 2.7702 12.1211 2.7702H61.879C62.2799 2.7702 62.6047 3.09482 62.6047 3.49589V64.9344C62.6046 65.3355 62.2799 65.6601 61.879 65.6601ZM12.8468 64.2086H61.1532V4.22159H12.8468V64.2086Z"
          )
          path(
            d="M56.0476 25.9465H17.953C16.6207 25.9465 15.5363 24.8546 15.5363 23.5132V10.175C15.5363 8.83344 16.6205 7.74237 17.953 7.74237H56.0476C57.3798 7.74237 58.4636 8.83344 58.4636 10.175V23.5132C58.4636 24.8546 57.3798 25.9465 56.0476 25.9465ZM17.953 9.19375C17.4205 9.19375 16.9878 9.63414 16.9878 10.175V23.5132C16.9878 24.0546 17.4205 24.495 17.953 24.495H56.0476C56.5791 24.495 57.0122 24.0546 57.0122 23.5132V10.175C57.0122 9.63414 56.5791 9.19375 56.0476 9.19375H17.953V9.19375Z"
          )
          path(
            d="M22.3051 21.6133H20.5516V20.0557H22.3051V21.6133ZM27.1858 15.8731L28.8337 12.7358H30.9046L28.2849 17.1323L31.0524 21.6132H28.9188L27.228 18.4125L25.5167 21.6132H23.4677L26.1295 17.1323L23.5309 12.7358H25.5588L27.1858 15.8731ZM33.7361 20.2265H37.6449V21.6132H31.9608V12.7358H33.7361V20.2265V20.2265ZM43.4127 19.2877C43.4127 18.9461 43.2863 18.6688 43.0533 18.4767C42.8001 18.2634 42.3777 18.0713 41.786 17.8791C40.7296 17.5799 39.948 17.2174 39.4195 16.7909C38.87 16.3855 38.6166 15.8089 38.6166 15.0833C38.6166 14.3576 38.912 13.7608 39.5251 13.2912C40.1378 12.8429 40.9196 12.6077 41.8914 12.6077C42.842 12.6077 43.6448 12.8638 44.2367 13.3763C44.8495 13.8881 45.1451 14.5287 45.1241 15.2966V15.3395H43.4127C43.4127 14.9341 43.2652 14.5927 42.9898 14.3577C42.7365 14.1022 42.3355 13.9741 41.8492 13.9741C41.3839 13.9741 41.0254 14.0804 40.7502 14.2944C40.4968 14.5076 40.37 14.7638 40.37 15.1052C40.37 15.3826 40.5179 15.6388 40.7931 15.8303C41.0674 16.0226 41.5318 16.2146 42.2081 16.428C43.1805 16.7053 43.9196 17.0685 44.4269 17.5162C44.913 17.9646 45.1663 18.541 45.1663 19.288C45.1663 20.0348 44.8708 20.6322 44.279 21.0799C43.6872 21.5072 42.9054 21.7415 41.9337 21.7415C40.9619 21.7415 40.1378 21.4861 39.4195 20.9947C38.7011 20.4829 38.3627 19.7995 38.3845 18.8824V18.8606H40.1168C40.1168 19.394 40.2645 19.7785 40.5814 20.0135C40.8985 20.269 41.363 20.376 41.9337 20.376C42.3987 20.376 42.7788 20.2909 43.032 20.0986C43.2863 19.8852 43.4127 19.6291 43.4127 19.2877ZM49.6036 15.8731L51.2515 12.7358H53.3005L50.702 17.1323L53.4491 21.6132H51.3148L49.6248 18.4125L47.9347 21.6132H45.8638L48.5468 17.1323L45.927 12.7358H47.9769L49.6036 15.8731Z"
          )
          path(
            d="M56.4907 68.4983H17.5302C17.1294 68.4983 16.8043 68.1734 16.8043 67.7726C16.8043 67.3715 17.1294 67.0469 17.5302 67.0469H56.4907C56.8919 67.0469 57.2164 67.3715 57.2164 67.7726C57.2166 68.1734 56.8921 68.4983 56.4907 68.4983Z"
          )
          path(
            d="M53.0261 71.2299H20.9737C20.5731 71.2299 20.248 70.9053 20.248 70.5042C20.248 70.1031 20.5731 69.7785 20.9737 69.7785H53.0261C53.4272 69.7785 53.7518 70.1031 53.7518 70.5042C53.7518 70.9053 53.4272 71.2299 53.0261 71.2299Z"
          )
          path(
            d="M57.125 44.128H16.8751C16.4743 44.128 16.1494 43.8031 16.1494 43.4023V37.0426C16.1494 36.6415 16.4745 36.3169 16.8751 36.3169H57.125C57.5254 36.3169 57.8507 36.6415 57.8507 37.0426V43.4023C57.8507 43.8031 57.5254 44.128 57.125 44.128ZM17.6008 42.6764H56.3993V37.7683H17.6008V42.6764Z"
          )
          path(
            d="M57.125 50.4657H16.8751C16.4743 50.4657 16.1494 50.1411 16.1494 49.7401V43.4021C16.1494 43.0014 16.4745 42.6765 16.8751 42.6765H57.125C57.5254 42.6765 57.8507 43.0014 57.8507 43.4021V49.7401C57.8507 50.1411 57.5254 50.4657 57.125 50.4657ZM17.6008 49.0144H56.3993V44.128H17.6008V49.0144Z"
          )
          path(
            d="M57.125 56.8256H16.8751C16.4743 56.8256 16.1494 56.5007 16.1494 56.0999V49.7403C16.1494 49.3392 16.4745 49.0146 16.8751 49.0146H57.125C57.5254 49.0146 57.8507 49.3392 57.8507 49.7403V56.0999C57.8507 56.5007 57.5254 56.8256 57.125 56.8256ZM17.6008 55.3741H56.3993V50.4657H17.6008V55.3741Z"
          )
          path(
            d="M44.7441 56.8256H29.2566C28.8559 56.8256 28.5309 56.5007 28.5309 56.0999V37.0425C28.5309 36.6414 28.8559 36.3168 29.2566 36.3168H44.7441C45.145 36.3168 45.4698 36.6414 45.4698 37.0425V56.0998C45.4699 56.5007 45.145 56.8256 44.7441 56.8256ZM29.9823 55.3741H44.0184V37.7683H29.9823V55.3741Z"
          )

  .col-sm-12.col-md-4.col-lg-3

  template(v-for="(item, i) in clientele", :key="'a' + i")
    .col-sm-12.col-md-12.col-lg-3
      .clientele-item-box
        .item-box.item-mini
          span {{ item.Месяц }} - {{ item.Год }}
    .col-sm-12.col-md-12.col-lg-3
      .clientele-item-box
        .item-box.item-mini
          span {{ item.Имя }}
    .col-sm-12.col-md-12.col-lg-3
      .clientele-item-box
        .item-box.item-mini
          span {{ item.Телефон }}
    .col-sm-12.col-md-12.col-lg-3
//- canvas#chart
</template>

<script>
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import { useStore } from "vuex";
import { ref, onMounted, watch, computed } from "vue";
import XLSX from "xlsx";
import { monthName } from "@/components/function/distrobject";
export default {
  setup() {
    const store = useStore();
    let addcalendar = "";
    const clientele = computed(() => {
      return store.state.clientele;
    });
    const siteName = computed(() => {
      return store.state.siteName;
    });
    const date = computed(() => {
      return store.state.date;
    });
    onMounted(() => {
      new Promise((r) => r(calendar())).then(() => updateCalendar());
      if (clientele.value.length == 0 && siteName.value)
        store.dispatch("getClientele");
    });
    function calendar() {
      let butSeeCalendar = {
        content: "Показать",
        onClick: (dp) => {
          calendarSend(dp.$el.value);
        },
      };
      let butClearCalendar = {
        content: "Очистить",
        onClick: () => {
          addcalendar.clear();
          store.commit("UPDATE_CLIENTELE", "");
        },
      };
      addcalendar = new AirDatepicker("#el2", {
        inline: true,
        view: "months",
        minView: "months",
        dateFormat: "MM.yyyy",
        multipleDatesSeparator: " - ",
        buttons: [butSeeCalendar, butClearCalendar],
        multipleDates: true,
      });
    }

    function updateCalendar() {
      addcalendar.update({
        selectedDates: [new Date()],
        onRenderCell({ date, type }) {
          const dateStore = new Date(store.state.date * 1000);
          const month = +dateStore.getMonth() - 1;
          const year = dateStore.getFullYear();
          if (type === "month") {
            if (date.getMonth() <= month && date.getFullYear() <= year) {
              return {
                disabled: true,
              };
            }
          }
        },
      });
    }

    watch(
      () => date.value,
      () => {
        updateCalendar();
      }
    );
    watch(
      () => siteName.value,
      (e) => {
        if (addcalendar) addcalendar.destroy();
        new Promise((r) => r(calendar())).then(() => updateCalendar());
        if (e) store.dispatch("getClientele");
      }
    );
    function saveXLSX() {
      // export json to Worksheet of Excel
      // only array possible
      let clients = XLSX.utils.json_to_sheet(clientele.value);
      var wsrows = [
        { wpx: 100 }, // "pixels"
        { wpx: 120 }, // "pixels"
        { wpx: 60 }, // "pixels"
        { wpx: 30 }, // "pixels"
      ];

      clients["!cols"] = wsrows;
      // A workbook is the name given to an Excel file
      let wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, clients, "Клиеты"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "clients.xlsx"); // name of the file is 'book.xlsx'
    }

    // function chart(params) {
    //   const canvas = document.getElementById("chart");
    //   const ctx = canvas.getContext("2d");
    //   ctx.fillStyle = "rgb(200, 0, 0)";
    //   ctx.fillRect(10, 0, 50, 50);
    // }
    function calendarSend(val) {
      const arr = val.replace(/\s/g, "").split("-");
      let months = new Set();
      let years = new Set();
      let data = {
        months: [],
        years: [],
      };
      for (const iterator of arr) {
        months.add(iterator.split(".")[0]);
        years.add(iterator.split(".")[1]);
      }
      data.months = [...months];
      data.years = [...years];
      store.dispatch("getClientele", data);
    }

    return {
      siteName,
      clientele,
      // getClientele,
      calendarSend,
      monthName,
      saveXLSX,
      calendar,
    };
  },
};
</script>

<style lang="sass">
canvas
  width: 100%
  height: 500px
  background: #e9e9e9
  margin-top: 20px
.clientele-item-box
  border: 1px solid #CECECE
  box-sizing: border-box
  border-radius: 5px
  p
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 23px
    text-align: center
    color: #000000
    margin: 10px 0
  .item-box
    background: #efefef
    border-radius: 0 0 5px 5px
    border-top: 1px solid #CECECE
    height: 215px
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

    span
      font-style: normal
      font-weight: bold
      font-size: 36px
      line-height: 47px
      text-align: center
      color: #000000
  .item-mini
    height: 30px
    border-top: none
    border-radius: 5px
    span
      font-style: normal
      font-weight: 600
      font-size: 16px
      line-height: 47px
      text-align: center
      color: #000000
  .item-button
    cursor: pointer
    svg
      transition: .3s
    &:hover svg
      fill: red

.air-datepicker
  width: 100%
.air-datepicker-cell.-selected-.-current-
  background: #FF6C52 !important
  color: #fff !important
.air-datepicker-cell.-selected-
  background: #FF6C52 !important
.air-datepicker-cell.-current-
  color: #FF6C52 !important
.air-datepicker-button
  color: #FF6C52 !important
</style>