<template lang="pug">
.menu-container
  .close-button(
    @click="closeButton",
    :class="{ 'close-button-open': showMenu }"
  ) 
    span 
  .menu(:class="{ 'menu-open': showMenu }")
    .menu-wrap
      router-link(to="/Main")
        img#logo(src="@/../public/img/logo.svg", alt="")
      my-button(
        :class="{ createQuizDisable: $store.state.reload == 'newQuiz' }",
        @click="createQuiz"
      ) Создать квиз
      .quiz-container
        p(v-if="getStoreSiteName.length > 0") Созданные квизы
        .check-box.all-quiz
          .radio-box(v-for="quiz in getStoreSiteName") 
            transition(name="fade")
              .del-quiz(
                v-if="$store.state.siteName == quiz",
                @click="delQuiz($event)",
                :data-quiz="quiz"
              )
            label.radio
              input(
                type="radio",
                name="quiz",
                v-model="addQuiz",
                :value="quiz",
                @click="getThisQuiz(quiz)"
              ) 
              span {{ quiz }}
    router-link.help(to="/Help")
      img(src="img/help.svg", alt="")
      span Служба поддержки
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";

import {
  distrObject,
  Toast,
  alert,
  setLokalDefault,
  settingQuiz,
} from "@/components/function/distrobject";
import { useRouter } from "vue-router";
export default {
  setup() {
    let showMenu = ref(false);
    let addQuiz = ref("");
    const store = useStore();
    const router = useRouter();
    // получаем названия квизов
    const getStoreSiteName = computed(() => {
      return store.state.allSiteName;
    });

    function closeButton() {
      showMenu.value ? (showMenu.value = false) : (showMenu.value = true);
    }

    // ожидаем появление квиза
    watch(
      () => store.state.allQuiz,
      (e) => {
        // передаем настройки в localstorage
        if (e.length !== 0) settingQuiz(e);
      }
    );
    watch(
      () => store.state.reload,
      (e) => {
        // после перезагрузки передаем данные
        if (
          store.state.allQuiz.length == 0 &&
          e !== "newQuiz" &&
          store.state.sitename
        )
          store.dispatch("getQuiz");
      },
      { deep: true }
    );
    watch(
      () => store.state.siteName,
      (e) => {
        const checkInterval = setInterval(() => {
          const checkInput = document.querySelector(`[value="${e}"]`);
          if (checkInput) {
            checkInput.checked = true;
            clearInterval(checkInterval);
            store.commit("RELOAD", e);
          }
        }, 100);
      }
    );
    function changeToggle() {
      if (store.state.siteName) {
        document.querySelector(
          `[value="${store.state.siteName}"]`
        ).checked = true;
        addQuiz.value = store.state.siteName;
      } else {
        document.querySelector(`[value="${addQuiz.value}"]`).checked = false;
        addQuiz.value = "";
      }
    }
    //запускаем получение названий квизов
    onMounted(() => {
      if (store.state.allQuiz.length == 0) store.dispatch("getQuizName");
      if (store.state.allQuiz.length !== 0) changeToggle();
    });
    // проверяем текущий квиз сохранен или нет

    function errQuizChanges() {
      let obj = JSON.stringify(distrObject());
      let obj2 = JSON.stringify(store.state.allQuiz);
      // console.log(obj);
      // console.log(obj2);
      return obj == obj2;
    }

    function getThisQuiz(site) {
      // если квиз сохранен переходим на выбранный
      if (errQuizChanges()) {
        if (site !== store.state.reload) store.commit("RELOAD", site);
        if (site !== store.state.siteName) store.dispatch("getQuiz");
        router.push({ name: "main" });
      } else {
        alert
          .fire({
            title: "Изменения не сохранены!",
            text: "Вы уверены что хотите перейти на другой квиз?",
          })
          .then((result) => {
            if (!result.isConfirmed) {
              changeToggle();
              return;
            }
            store.commit("RELOAD", site);
            store.dispatch("getQuiz");
            router.push({ name: "main" });
          });
      }
    }
    function defaultSettings() {
      setLokalDefault();
      store.commit("RELOAD", "newQuiz");
      store.commit("UPDATE_ALLQUIZRESET");
      addQuiz.value = "";
      router.push({ name: "main" });
    }
    function createQuiz() {
      if (errQuizChanges()) {
        defaultSettings();
      } else {
        alert
          .fire({
            title: "Изменения не сохранены!",
            text: "Вы уверены что хотите перейти на другой квиз?",
          })
          .then((result) => {
            if (!result.isConfirmed) {
              changeToggle();
              return;
            }
            defaultSettings();
          });
      }
    }
    function delQuiz(e) {
      const siteName = e.target.getAttribute("data-quiz");
      alert
        .fire({
          title: `Удаление ${siteName}!`,
          text: `Вы уверены что хотите удалить этот квиз?`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append("site", siteName);
            fetch("https://rquiz.mediarama.by/deleteQuiz.php", {
              method: "POST",
              body: formData,
            })
              .then((response) => response.json())
              .then((result) => {
                if (!result) {
                  Toast.fire({
                    icon: "error",
                    title: "Ошибка отпраки!",
                  });
                  return;
                }
                for (const key in getStoreSiteName.value) {
                  if (getStoreSiteName.value[key] == siteName)
                    getStoreSiteName.value.splice(key, 1);
                }
                // for (const key in allSiteName.value) {
                //   if (allSiteName.value[key] == siteName)
                //     allSiteName.value.splice(key, 1);
                // }
                defaultSettings();
                Toast.fire({
                  icon: "success",
                  title: "Квиз удален!",
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
    return {
      delQuiz,
      getStoreSiteName,
      createQuiz,
      getThisQuiz,
      addQuiz,
      closeButton,
      showMenu,
    };
  },
};
</script>

<style lang="sass" scoped>
.menu-container
  position: relative
  background: #383838
  z-index: 99
  @media screen and(max-width:767px)
    position: absolute
  .close-button
    position: absolute
    right: -50px
    top: 10px
    width: 40px
    height: 40px
    cursor: pointer
    border: none
    span
      width: 30px
      height: 4px
      border-radius: 50px
      background: #383838
      display: block
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
      margin: auto
      transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6)
      &::before,
      &::after
        content: ""
        width: 30px
        height: 4px
        position: absolute
        left: 0
        right: 0
        top: -20px
        bottom: 0
        margin: auto
        background: #383838
        transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6)
      &::after
        top: 20px
  .close-button-open
    span
      height: 0
      &::before
        transform: rotate(45deg)
        top: 0px
      &::after
        top: 0px
        transform: rotate(-45deg)
  #logo
    margin: 20px 0px 40px

  .menu
    width: 0px
    height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    left: -320px
    transition: .6s cubic-bezier(0.65, 0, 0.35, 1)
    z-index: 1
    .menu-wrap
      width: 100%
      overflow: hidden
      display: flex
      flex-direction: column
      align-items: center

    .quiz-container
      margin-top: 20px
      overflow: auto
      max-width: 280px
      width: 100%
      padding: 0 0 10px 0
      margin-bottom: 60px
    p
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 23px
      color: #FFFFFF
      text-transform: uppercase
    .check-box
      width: 100%
      label
        font-style: normal
        font-weight: normal
        font-size: 18px
        line-height: 23px
        color: #FFFFFF
        display: flex
        align-items: center
        cursor: pointer
        input
          margin-right: 10px
          min-width: 18px
          height: 18px
  .menu-open
    left: 0
    width: 320px
  .createQuizDisable
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25) inset
    pointer-events: none
  .radio-box
    display: flex
    align-items: center
    position: relative
  .del-quiz
    min-width: 20px
    height: 20px
    border: 1px solid #fff
    border-radius: 60px
    box-sizing: border-box
    margin-right: 10px
    margin-left: 2px
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    &::before,&::after
      content: ''
      position: absolute
      height: 1px
      width: 12px
      transform: rotate(45deg)
      background: #fff
    &::after
      transform: rotate(-45deg)

  .radio
    margin: 8px 0
    display: block
    cursor: pointer
    input
      display: none

      & + span
        line-height: 22px
        height: 20px
        padding-left: 22px
        display: block
        position: relative
        font-size: 16px
        transition: .3s
        &:not(:empty)
          padding-left: 22px + 8

        &:before,
        &:after
          content: ''
          width: 20px
          height: 20px
          display: block
          border-radius: 50%
          left: 2px
          top: 0
          position: absolute

        &:before
          background: #fff
          transition: background .2s ease, transform .4s cubic-bezier(.175, .885, .32, 2)

        &:after
          background: #fff
          transform: scale(.78)
          transition: transform .6s cubic-bezier(.175, .885, .32, 1.4)

      &:checked + span
        transform: translateX(28px)
        &:before
          transform: scale(1.04)
          background: #FF6C52

        &:after
          transform: scale(.4)
          transition: transform .3s ease

      &:hover
        input
          & + span
            &:before
              transform: scale(.92)

            &:after
              transform: scale(.74)

          &:checked + span
            &:after
              transform: scale(.4)
  .help
    display: flex
    align-items: center
    justify-content: center
    padding: 15px
    position: absolute
    bottom: 0
    min-width: 225px
    cursor: pointer
    text-decoration: none
    img
      margin-right: 10px
      transition: .3s
    span
      color: #fff
    &:hover img
      transform: translate(5px,-5px)
      cursor: pointer
</style>