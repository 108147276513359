import { createApp } from 'vue'
import App from './App'
import router from '@/router/router'
import store from '@/store' 
import components from '@/components/UI' 
import quizPage from '@/components/quiz' 
import VueSweetalert2 from 'vue-sweetalert2';
const app = createApp(App)
components.forEach(component => {
  app.component(component.name, component)
});
quizPage.forEach(page => {
  app.component(page.name, page)
});
app
.use(quizPage) 
.use(VueSweetalert2) 
.use(components)  
.use(router) 
.use(store)
.mount('#app')

