<template lang="pug">
.createUser-header
  .auth__steps
    a.registerPage(@click="logIn('register')") Регистрация
    a.registerPage(@click="logIn('login')") Вход
  h2 ВОСТОНОВЛЕНИЕ ПАРОЛЯ
  p Введите email указанный при регистрации
.auth__page
  input(
    v-model.trim="email",
    type="email",
    name="email",
    placeholder="Email",
    required
  ) 

  my-button(@click="createUser") Востоновить
</template>

  <script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { alertErr, alertOk } from "@/components/function/distrobject";
export default {
  emits: ["pageChange"],
  name: "recovery-password",
  setup(_, { emit }) {
    const router = useRouter();
    let email = ref("");
    function logIn(params) {
      emit("pageChange", params);
    }
    function createUser() {
      const formData = new FormData();
      formData.append("email", email.value);

      fetch("https://rquiz.mediarama.by/recoveryPassword.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            alertErr.fire({
              text: "Пользователь не найден!",
            });
            return;
          }
          alertOk.fire({
            text: "Пароль выслан на почту!",
          });
          logIn("login");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return {
      createUser,
      logIn,
      router,
      email,
    };
  },
};
</script>

<style lang="sass" scoped>
.createUser-header
  p
    text-align: center
</style>