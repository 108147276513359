<template lang="pug">
.createUser-header
  .auth__steps
    a.registerPage(@click="logIn('login')") Вход
    span шаг {{ authPageData }} из 2
  h2 РЕГИСТРАЦИЯ
.auth__page(v-show="authPageData == 1")
  input(
    v-model.trim="formDATA.username",
    type="name",
    name="name",
    placeholder="Имя",
    required
  ) 
  input(
    v-model.trim="formDATA.userphone",
    type="text",
    name="phone",
    placeholder="Телефон",
    autocomplete="off",
    @click="inputMask",
    required
  ) 
  my-button(@click="goPage(2)", type="button") Далее
.auth__page(v-if="authPageData == 2")
  input(
    v-model.trim="formDATA.email",
    type="email",
    name="email",
    placeholder="Email",
    required
  ) 
  input(
    v-model.trim="formDATA.password",
    type="password",
    name="password",
    placeholder="Пароль",
    autocomplete="on",
    required
  ) 
  .but-box
    my-button#prev(@click="goPage(1)")
      svg(
        fill="none",
        height="24",
        stroke="#fff",
        stroke-linecap="round",
        stroke-linejoin="round",
        stroke-width="2",
        viewBox="0 0 24 24",
        width="24",
        xmlns="http://www.w3.org/2000/svg"
      )
        line(x1="20", x2="4", y1="12", y2="12")
        polyline(points="10 18 4 12 10 6") 
    my-button(@click="createUser") Создать аккаунт
</template>

<script>
import Inputmask from "inputmask";
import { ref } from "vue";
import { useRouter } from "vue-router";
import {
  errPass,
  alertErr,
  setLokalDefault,
} from "@/components/function/distrobject";
export default {
  emits: ["pageChange"],
  name: "register-form",
  setup(_, { emit }) {
    const router = useRouter();
    let formDATA = ref(
      { username: "" },
      { userphone: "" },
      { email: "" },
      { password: "" }
    );
    let authPageData = ref(1);
    function inputMask() {
      Inputmask("+375 (99) 999-99-99").mask('[name="phone"]');
    }
    function logIn(params) {
      emit("pageChange", params);
    }
    function goPage(page) {
      if (!formDATA.value.username) {
        alertErr.fire({
          text: "Заполните поле 'Имя'!",
        });
        return;
      }
      if (!formDATA.value.userphone) {
        alertErr.fire({
          text: "Заполните поле 'Телефон'!",
        });
        return;
      }
      if (errPhone()) authPageData.value = page;
      else
        alertErr.fire({
          text: "Введен не верный номер!",
        });
    }
    function errPhone() {
      const q = formDATA.value.userphone.replace(
        /(_)|(-)|\s|[()]|(\+)(375)/g,
        ""
      );
      const kod = q.slice(0, 2);
      const kodArr = ["25", "29", "33", "44"].find((e) => e == kod);
      const numb = new Set(q.slice(2, 10));
      const out = kodArr == undefined || numb.size < 2 ? false : true;
      return (out && q.length == 9) || false;
    }

    function createUser() {
      if (!formDATA.value.email) {
        alertErr.fire({
          text: "Заполните поле 'Email'!",
        });
        return;
      }
      if (!formDATA.value.password) {
        alertErr.fire({
          text: "Заполните поле 'Пароль'!",
        });
        return;
      }
      if (!errPass(formDATA.value.password)) return;
      const formData = new FormData();
      formData.append("name", formDATA.value.username);
      formData.append("phone", formDATA.value.userphone);
      formData.append("email", formDATA.value.email);
      formData.append("password", formDATA.value.password);

      fetch("https://rquiz.mediarama.by/createUser.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            alertErr.fire({
              text: "Такой аккаунт уже есть!",
            });
            return;
          }
          document.cookie = `m-quiz-auth=${result[1]}; max-age=172800`;
          setLokalDefault();
          router.push({ name: "main" });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return {
      errPass,
      createUser,
      logIn,
      inputMask,
      router,
      goPage,
      authPageData,
      formDATA,
      errPhone,
    };
  },
};
</script>

<style lang="sass">
.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s ease

.fade-enter-from,
.fade-leave-to
  opacity: 0
</style>