<template lang="pug">
.reply-box.range_check(:class="{ 'reply-box-select': seletPageAll }")
  .reply-box__reply
    img(src="@/../public/img/polzunok.svg")
    .input-box
      label 
        p минимальное занчение
        input(
          type="number",
          name="min",
          placeholder="min",
          :value="name.min",
          @input="setRangeMin($event, idx)"
        )
      label
        p максимальное занчение
        input(
          type="number",
          name="max",
          placeholder="max",
          :value="name.max",
          @input="setRangeMax($event, idx)"
        )
      label
        p шаг
        input(
          type="number",
          name="step",
          placeholder="step",
          :value="name.step",
          @input="setRangeStep($event, idx)"
        )
      label
        p единица измерения
        input(
          type="text",
          name="edenica",
          placeholder="руб",
          :value="name.edenica",
          @input="setRangeEdenica($event, idx)"
        )
    label
      .reply-check
      input(
        type="text",
        name="checkboxText",
        placeholder="Любой текст",
        :value="name.reply[0].replytext",
        @input="setQuestion($event, idx)"
      )
    .reply-nav-box
      input(
        type="number",
        name="gopage",
        title="На какую страницу ведет",
        :value="name.reply[0].gopage",
        @input="noNegativeNumber($event, idx)"
      )
</template>

<script>
export default {
  name: "range-check",
  props: [
    "name",
    "idx",
    "setPlaceholder",
    "noNegativeNumber",
    "setQuestion",
    "seletPageAll",
    "setRangeMin",
    "setRangeMax",
    "setRangeStep",
    "setRangeEdenica",
  ],
  setup() {},
};
</script>

<style lang="sass" scoped>
.range_check
  .reply-box__reply
    width: 100%
    border-radius: 4px
    position: relative
    margin-bottom: 5px
    [name="gopage"]
      width: 60px
      margin-top: 10px
    .input-box
      display: flex
      flex-wrap: wrap
      label
        margin-right: 10px
        max-width: 140px
        margin-top: 10px
    label
      position: relative
      margin-bottom: 0
      [name="checkboxText"]
        margin-top: 10px
        padding-left: 35px
      .reply-check
        width: 30px
        height: 30px
        background: #FFFFFF
        box-sizing: border-box
        border-radius: 4px
        position: absolute
        left: 0px
        top: 10px
        border: 1px solid #cacaca
        &::before,&::after
          content: ""
          height: 2px
          background: #000
          width: 10px
          position: absolute
          left: 0
          right: 8px
          top: 1.5px
          bottom: 0
          margin: auto
          transform: rotate(45deg)
        &::after
          right: -8px
          top: -2px
          width: 15px
          transform: rotate(-45deg)
</style>