<template lang="pug">
mome-but
section.help
  menu-nav
  .help-container
    .container
      .row
        .col-sm-12.col-md-12.col-lg-12
          h2 Служба поддержки
    .container
      .row
        .col-sm-12.col-md-7.col-lg-7
          .info-container
            .title
              h3 Выберите удобный способ связи
            a.img-box(href="")
              img(src="img/telegram.svg")
              p Telegram
            a.img-box(href="")
              img(src="img/whatsAap.svg")
              p WhatsAap
            a.img-box(href="")
              img(src="img/viber.svg")
              p Viber
            //- a.img-box(href="")
            //-   img(src="img/email.svg")
            //-   p Email
          .row
            .col-sm-12.col-md-6.col-lg-6
              .input-box
</template>

<script>
import MenuNav from "@/components/MenuNav";

export default {
  components: {
    MenuNav,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
.help
  background: #fff !important
  position: relative
  display: flex
  h2
    margin-top: 55px
  .help-container
    width: 100%
    overflow-y: auto
    height: 100vh
    padding-bottom: 35px
  .info-container
    border: 1px solid #cecece
    border-radius: 5px 5px 5px 5px
    padding: 15px
    margin-top: px
    margin-bottom: 30px
  .img-box
    display: flex
    margin-bottom: 10px
    cursor: pointer
    text-decoration: none
    color: #7c7c7c
    font-weight: 600
    img
      margin-right: 10px
</style>