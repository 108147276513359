<template lang="pug">
.createUser-header
  .auth__steps
    a.registerPage(@click="logIn('register')") Регистрация
    span 
  h2 ВХОД
.auth__page
  input(
    v-model.trim="email",
    type="email",
    name="email",
    placeholder="Email",
    required
  ) 
  input(
    v-model.trim="password",
    type="password",
    name="password",
    placeholder="Пароль",
    autocomplete="on",
    required
  ) 
  my-button(
    @click="getUser",
    title="SweetAlert2 does NOT sanitize this parameter. It is the developer's responsibility to escape any user input when using the html option, so XSS attacks would be prevented. text '' #. ... The icon of the popup. SweetAlert2 comes with 5 built-in icon which will show a corresponding icon animation: warning , error , success , info , and question . It can either be put in the array under the key  or passed as the third parameter of the function. iconColor undefined #."
  ) Войти
  a.recoveryPage(@click="logIn('recovery')") Забыли пароль?
</template>

  <script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { alertErr } from "@/components/function/distrobject";
export default {
  emits: ["pageChange"],
  name: "login-form",
  setup(_, { emit }) {
    const router = useRouter();
    let email = ref("");
    let password = ref("");
    function logIn(params) {
      emit("pageChange", params);
    }
    function getUser() {
      const formData = new FormData();
      formData.append("email", email.value);
      formData.append("password", password.value);

      fetch("https://rquiz.mediarama.by/getAuth.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.text())
        .then((result) => {
          if (result == "false" || !result) {
            alertErr.fire({
              text: "Неверный пользователь или пароль!",
            });
            return;
          }
          document.cookie = `m-quiz-auth=${result}; max-age=172800`;
          // setLokalDefault();
          router.push({ name: "main" });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return {
      getUser,
      logIn,
      router,
      email,
      password,
    };
  },
};
</script>

<style lang="sass" scoped>
.recoveryPage
  margin-top: 15px
  color: #999
  cursor: pointer
</style>