<template lang="pug">
button.my-button
  slot
</template>

<script>
export default {
  name: "my-button",
};
</script>

<style lang="sass">
.my-button
  width: 200px
  height: 50px
  min-height: 50px
  border-radius: 50px
  border: none
  outline: none
  background: #FF6C52
  color: #fff
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25)
  margin-top: 10px
  transition: .3s
  cursor: pointer
  &:hover
    background: #ED492C
  &:active
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25))
</style>