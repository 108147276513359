<template lang="pug">
mome-but
section.home
  menu-nav
  .home-container
    .container
      .row
        .col-sm-12.col-md-12.col-lg-12
          h2 Аккаунт
    .container
      .row
        .col-sm-12.col-md-5.col-lg-5
          .info-container
            .info-wrap
              span Email
              .info-box
                .info-but
                  p(style="cursor: auto") {{ email }}
            .info-wrap
              span Пароль
              .info-box
                .info-but(@click="addMod('password')")
                  p Изменить
                  img(src="img/pen.svg", alt="")
                transition(name="slide-fade")
                  .modification.password(v-if="password.show")
                    input#password(
                      name="password",
                      type="password",
                      placeholder="Новый пароль",
                      @keyup.enter="editPass"
                    )
                    input#password2(
                      name="password2",
                      type="password",
                      placeholder="Повторите пароль",
                      @keyup.enter="editPass"
                    )
                    my-button(@click="editPass") Изменить
            .info-wrap
              span Имя
              .info-box
                .info-but(@click="addMod('name')")
                  p {{ name.name }}
                  img(src="img/pen.svg", alt="")
                transition(name="slide-fade")
                  .modification(v-if="name.show")
                    input#name(
                      name="name",
                      type="text",
                      placeholder="Новое имя",
                      v-model="name.name",
                      @keyup.enter="editMod('name')"
                    )
                    my-button(@click="editMod('name')") Изменить
            .info-wrap
              span Телефон
              .info-box
                .info-but(@click="addMod('phone')")
                  p {{ phone.phone }}
                  img(src="img/pen.svg", alt="")
                transition(name="slide-fade")
                  .modification(v-if="phone.show")
                    input#phone(
                      name="phone",
                      type="text",
                      placeholder="Новый телефон",
                      v-model="phone.phone",
                      @click="inputMask",
                      @keyup.enter="editMod('phone')"
                    )
                    my-button(@click="editMod('phone')") Изменить
            .info-wrap
              span Дата
              .info-box
                .info-but
                  p(style="cursor: auto") {{ date }}

        .col-sm-12.col-md-7.col-lg-7
          .info-container
            .title
              h2 Расскажите о себе
              p Это поможет быстрее реагировать на просьбы, подключать тех. поддержку или маркетологов для решения ваших задач.
            .row
              .col-sm-12.col-md-6.col-lg-6
                .input-box
                  span КТО ВЫ
                  input#who(
                    @click="toggleList($event)",
                    placeholder="КТО ВЫ",
                    type="text",
                    readonly,
                    v-model="clientInfo.who"
                  )
                  ul
                    li(@click="selectLi", v-for="item in questions.who") {{ item }}

                .input-box
                  span ФОРМАТ ВАШЕЙ КОМПАНИИ
                  input#format(
                    @click="toggleList($event)",
                    placeholder="ФОРМАТ ВАШЕЙ КОМПАНИИ",
                    type="text",
                    readonly,
                    v-model="clientInfo.format"
                  )
                  ul
                    li(@click="selectLi", v-for="item in questions.format") {{ item }}
                .input-box
                  span САЙТ КОМПАНИИ
                  input#target(
                    placeholder="САЙТ КОМПАНИИ",
                    type="text",
                    v-model="clientInfo.site"
                  )
              .col-sm-12.col-md-6.col-lg-6
                .input-box
                  span ВАША НИША
                  input#delo(
                    @click="toggleList($event)",
                    placeholder="ВАША НИША",
                    type="text",
                    readonly,
                    v-model="clientInfo.delo"
                  )
                  ul
                    li(@click="selectLi", v-for="item in questions.delo") {{ item }}
                .input-box
                  span ОСНОВНАЯ ЦЕЛЬ ИСПОЛЬЗОВАНИЯ КВИЗ
                  input#target(
                    @click="toggleList($event)",
                    placeholder="ОСНОВНАЯ ЦЕЛЬ ИСПОЛЬЗОВАНИЯ КВИЗ",
                    type="text",
                    readonly,
                    v-model="clientInfo.target"
                  )
                  ul
                    li(@click="selectLi", v-for="item in questions.target") {{ item }}
                .input-box
                  span СОХРАНИТЬ ДАННЫЕ
                  input#save(type="submit", @click="editInfo", value="Готово")
</template>

<script>
import MenuNav from "@/components/MenuNav";
import { computed, onMounted, ref, watch } from "vue";
import { Toast, errPass, alertErr } from "@/components/function/distrobject";
import Inputmask from "inputmask";
import { useStore } from "vuex";
export default {
  components: {
    MenuNav,
  },
  setup() {
    const store = useStore();
    const clientEmail = computed(() => {
      return store.getters.clientEmail;
    });
    const home = computed(() => {
      return store.state.home;
    });

    let email = ref("");
    let password = ref({ show: false });
    let name = ref({ name: "" }, { show: false });
    let phone = ref({ phone: "" }, { show: false });
    let date = ref("");
    let promoKod = ref("");
    let clientInfo = ref({
      who: "",
      format: "",
      delo: "",
      target: "",
      site: "",
    });
    let questions = ref({
      who: [
        "Маркетолог или таргетолог",
        "Владелец бизнеса",
        "Продукт менеджер",
        "Генеральный директор или руководитель",
        "Менеджер отдела продаж",
        "Блогер",
      ],

      format: [
        "Корпорация",
        "Стартап",
        "Небольшой бизнес",
        "Средний бизнес",
        "Малый бизнес",
        "Частные услуги",
      ],

      delo: [
        "Образование",
        "Недвижимость",
        "Услуги населению",
        "Обустройство (ремонт, мебель)",
        "Продажа товаров",
        "Маркетинговое агенство",
        "Другое",
      ],

      target: [
        "Получение заявок",
        "Тестирование стратегий",
        "Исследования",
        "Сегментация",
        "В качестве теста",
        "Интерактив для аудитории",
      ],
    });

    function inputMask() {
      Inputmask("+375 (99) 999-99-99").mask('[name="phone"]');
    }
    function addMod(n) {
      eval(n).value.show
        ? (eval(n).value.show = false)
        : (eval(n).value.show = true);
    }
    function writeVar(em, n, phn, dat) {
      email.value = em;
      name.value.name = n;
      phone.value.phone = phn;
      let newDate = new Date(dat * 1000);
      date.value =
        newDate.getDate() +
        "." +
        newDate.getMonth() +
        "." +
        newDate.getFullYear() +
        "г";
    }

    function editMod(n) {
      const formData = new FormData();
      formData.append("email", clientEmail.value);
      formData.append("data", document.getElementById(n).value);
      formData.append("name", document.getElementById(n).name);
      fetch("https://rquiz.mediarama.by/editUser.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            Toast.fire({
              icon: "error",
              title: "Ошибка сохранения!",
            });
            return;
          }
          Toast.fire({
            icon: "success",
            title: "Сохранен!",
          });
          addMod(n);
          const { email, name, phone, date } = result;
          writeVar(email, name, phone, date);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    onMounted(() => {
      if (store.state.home.length == 0) store.dispatch("getMod");
      getMod();
    });
    watch(
      () => home.value,
      (e) => {
        const { email, name, phone, date, info } = e;
        if (info) clientInfo.value = JSON.parse(info);
        writeVar(email, name, phone, date);
      },
      { deep: true }
    );
    function getMod() {
      const { email, name, phone, date, info, promo } = home.value;
      if (info) clientInfo.value = JSON.parse(info);
      writeVar(email, name, phone, date);
      if (promo) promoKod.value = promo;
    }
    function editPass() {
      const pass1 = document.getElementById("password").value;
      const pass2 = document.getElementById("password2").value;
      if (errPass(pass1) && errPass(pass2))
        if (pass1 == pass2) editMod("password");
        else
          alertErr.fire({
            text: "Пароли не совпадают!",
          });
    }
    function toggleList(e) {
      document.querySelectorAll(".openUl").forEach((x) => {
        if (e.target.nextElementSibling == x) return;
        x.classList.remove("openUl");
      });
      e.target.nextElementSibling.classList.toggle("openUl");
    }
    function selectLi(e) {
      const el = e.target.parentElement.previousSibling;
      clientInfo.value[el.id] = e.target.innerHTML;
      el.value = e.target.innerHTML;
      e.target.parentElement.classList.remove("openUl");
    }
    function clientInfoErr() {
      let arrErr = new Set();
      for (const key in clientInfo.value) {
        if (clientInfo.value[key]) arrErr.add(clientInfo.value[key]);
      }
      if (arrErr.size == 5) return true;
      else {
        alertErr.fire({
          text: "Заполните все поля пожалуйста.",
        });
        return false;
      }
    }
    function editInfo() {
      if (!clientInfoErr()) return;
      const formData = new FormData();
      formData.append("email", clientEmail.value);
      formData.append("data", JSON.stringify(clientInfo.value));
      formData.append("name", "info");
      fetch("https://rquiz.mediarama.by/editUser.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            Toast.fire({
              icon: "error",
              title: "Ошибка сохранения!",
            });
            return;
          }
          Toast.fire({
            icon: "success",
            title: "Сохранен!",
          });
          const { info } = result;
          if (info) clientInfo.value = JSON.parse(info);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return {
      promoKod,
      home,
      editInfo,
      clientInfo,
      selectLi,
      toggleList,
      questions,
      editPass,
      email,
      password,
      name,
      phone,
      date,
      inputMask,
      getMod,
      addMod,
      editMod,
      clientEmail,
    };
  },
};
</script>

<style lang="sass" scoped>
.home
  background: #fff !important
  position: relative
  display: flex
  h2
    margin-top: 55px
  .home-container
    width: 100%
    overflow-y: auto
    height: 100vh
    padding-bottom: 35px
  .info-container
    border: 1px solid #cecece
    border-radius: 5px 5px 5px 5px
    padding: 15px
    margin-top: px
    margin-bottom: 30px
  .info-wrap
    span
      font-style: normal
      font-weight: bold
      font-size: 16px
      line-height: 29px
      color: #000000
    p
      margin: 3px 0
  .info-box
    display: flex
    align-items: center
    min-height: 40px
    margin-top: -10px
    flex-wrap: wrap
    .info-but
      cursor: pointer
      display: flex
      align-items: center
      margin-right: 20px
      img
        margin-left: 10px

  .modification
    display: flex
    align-items: center
    max-width: 280px

    .my-button
      margin: 0 15px
      height: 35px
      min-height: 35px
      min-width: 100px
    .close
      cursor: pointer
  .password
    max-width: 400px
    input
      &:first-child
        margin-right: 5px
  .title
    h2
      margin: 0
    p
      margin-top: 0
      margin-bottom: 30px
  .input-box
    position: relative
    max-width: 420px
    width: 100%
    margin-bottom: 20px
    input
      cursor: pointer
    span
      margin-bottom: 10px
      display: block
    ul
      padding: 0
      display: inline-block
      background: #fff
      margin-top: 0
      display: none
      position: absolute
      z-index: 1
      li
        list-style: none
        padding: 10px 15px
        margin: 0
        transition: .3s
        cursor: pointer
        top: 30px
        &:hover
          background: #FF6C52
          color: #fff
    .openUl
      animation: upList .3s linear
      display: block
      box-shadow: 0px 2px 5px rgba(0, 0, 0, .25)
  #save
    border: 1px solid #FF6C52
    outline: none
    background: #FF6C52
    color: #fff
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25)
    transition: .3s
    cursor: pointer
    &:hover
      background: #ED492C
    &:active
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25))

@keyframes upList
  0%
    transform: translateY(10px)
    opacity: 0
  100%
    transform: translateY(0px)
    opacity: 1

.slide-fade-enter-active,.slide-fade-leave-active
  transition: all 0.3s ease-out

.slide-fade-enter-from,
.slide-fade-leave-to
  transform: translateX(20px)
  opacity: 0
</style>