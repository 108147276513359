<template lang="pug">
section.settings
  h3 Настройки
  .row
    .col-sm-6.col-md-6.col-lg-4
      label 
        p Укажите название сайта*
        input(
          v-model="options.siteName",
          name="siteName",
          type="text",
          placeholder="saite.by"
        )
    .col-sm-6.col-md-6.col-lg-4
      label 
        p Укажите телеграм id
        input(
          v-model="options.telegramId",
          name="telegramId",
          type="number",
          placeholder="id"
        )
    .col-sm-6.col-md-6.col-lg-4
      label 
        p Укажите телеграм id менеджера
        input(
          v-model="options.telegramIdManager",
          name="telegramIdManager",
          type="number",
          placeholder="id"
        )
    .col-sm-6.col-md-6.col-lg-4
      label 
        p Укажите email*
        input(
          v-model="options.clientEmail",
          name="clientEmail",
          type="email",
          placeholder="Email"
        )
    .col-sm-6.col-md-6.col-lg-4
      label 
        p Укажите номер счетчика yandex
        input(
          v-model="options.yaMetrica",
          name="yaMetrica",
          type="number",
          placeholder="Номер счетчика"
        )
    color
</template>

<script>
import Color from "@/components/settings/Color";
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { getLocal } from "@/components/function/distrobject";
export default {
  components: {
    Color,
  },
  setup() {
    const options = ref({
      siteName: "",
      telegramId: "",
      telegramIdManager: "",
      clientEmail: "",
      yaMetrica: "",
    });
    const store = useStore();
    // получаем выбранный квиз

    function setLocal(key) {
      localStorage.setItem(key, JSON.stringify(options.value));
    }
    onMounted(() => {
      if (!localStorage.options) {
        setLocal("options");
      } else {
        options.value = getLocal("options");
      }
    });
    watch(
      () => options.value,
      (e) => {
        options.value.siteName = e.siteName
          .replace(/^http(s?):\/\//i, "")
          .split("/")[0];
        setLocal("options");
        store.commit(
          "UPDATE_SITENAME",
          e.siteName.replace(/^http(s?):\/\//i, "").split("/")[0]
        );
        store.commit("UPDATE_CLIENTNAME", e.clientEmail);
      },
      { deep: true }
    );

    watch(
      () => store.state.allQuiz,
      () => {
        options.value = getLocal("options");
      },
      { deep: true }
    );
    return {
      options,
    };
  },
};
</script>

<style lang="sass">
</style>