<template lang="pug">
mome-but
section.main
  menu-nav
  //- menu-nav(@creatQuiz="creatQuiz", @addQuizGenerate="addQuizGenerate")
  .settings-nav-container
    .container 
      .row
        .col-sm-12.col-md-12.col-lg-12
          .settings-nav-button
            button(
              @click="goPage('settings')",
              :="{ class: datatab == 'settings' && 'active' }"
            ) Общие настройки

            button(
              @click="goPage('quiz')",
              :="{ class: datatab == 'quiz' && 'active' }"
            ) Квиз

            button(
              @click="goPage('analitics')",
              :="{ class: datatab == 'analitics' && 'active' }"
            ) Аналитика
            button(
              @click="goPage('clientele')",
              :="{ class: datatab == 'clientele' && 'active' }"
            ) Клиенты
      .row.justify-content-center
        .col-sm-12.col-md-12.col-lg-12
          .settings-container
            .settings-data-box(
              data-page="settings",
              v-show="datatab === 'settings'"
            )
              settings
            .menu-data-box(data-page="quiz", v-show="datatab === 'quiz'")
              quiz
            .menu-data-box(
              data-page="analitics",
              v-show="datatab === 'analitics'"
            )
              analitics
            .menu-data-box(
              data-page="clientele",
              v-show="datatab === 'clientele'"
            )
              clientele
  //- .nav-container(v-if="!addQuiz")
  //-   .container 
  //-     my-button(@click="creatQuiz(true)") Создать квиз
</template>

<script>
import Quiz from "@/components/quiz/Quiz";
import Settings from "@/components/settings/Settings";
import Analitics from "@/components/analitics/Analitics";
import Clientele from "@/components/clientele/Clientele";
import MenuNav from "@/components/MenuNav";
import { ref } from "vue";

export default {
  components: {
    Quiz,
    Settings,
    MenuNav,
    Analitics,
    Clientele,
  },
  setup() {
    let datatab = ref("settings");
    let addQuiz = ref(false);

    function goPage(params) {
      const el = document.querySelector(`[data-page="${params}"]`);
      el.classList.add("animate-up");
      datatab.value = params;
    }
    return {
      goPage,
      addQuiz,
      datatab,
    };
  },
};
</script>

<style lang="sass" scoped>
.main
  background: #fff !important
  position: relative
  overflow: hidden
  display: flex
  .settings-nav-container
    width: 100%
    height: 100vh
    transition: .4s cubic-bezier(0.65, 0, 0.35, 1)
    overflow-y: auto
    .settings-nav-button
      margin-top: 60px
    button
      padding: 15px 0 12px
      width: 100%
      max-width: 250px
      border-radius: 10px 10px 0px 0px
      border: 1px solid #cecece
      box-sizing: border-box
      background: #FFFFFF
      margin: -1px 0
      color: #000
      cursor: pointer
      transition: .3s
      box-sizing: border-box
      margin-left: -1px
      @media screen and(max-width: 530px)
        max-width: 100%
      &:first-child
        margin-left: 0px
    .active
      background: #FF6C52
      color: #fff
      border: 1px solid #FF6C52
  .menu-container-open
    transform: translateX(0px)
  .settings-container
    border: 1px solid #cecece
    border-radius: 0 5px 5px 5px
    padding: 15px
    margin-bottom: 70px
    .settings-data-box
      transition: .3s

.animate-up
  animation: animate-up .6s cubic-bezier(0.22, 1, 0.36, 1)
@keyframes animate-up

  0%
    opacity: 0
    transform: translateY(20px)
  100%
    opacity: 1
    transform: translateY(0px)
</style>