import MyButton from "@/components/UI/MyButton";
import LoginForm from "@/components/UI/LoginForm";
import RegisterForm from "@/components/UI/RegisterForm";
import RecoveryPassword from "@/components/UI/RecoveryPassword";
import HomeBut from "@/components/UI/HomeBut";
export default[
  MyButton,
  LoginForm,
  RegisterForm,
  RecoveryPassword,
  HomeBut
]