import Index from "@/page/Index"
import LogIn from "@/page/LogIn"
import Main from "@/page/Main"
import Home from "@/page/Home"
import Help from "@/page/Help"
import { createRouter, createWebHistory } from "vue-router"
const getCookie = ()=>{
    const getCook = document.cookie
    const splitCook = getCook.split(";");
    const out = splitCook.find((elm) => elm.split("=")[0].trim() == "m-quiz-auth");
    if(out) return true;
}

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        beforeEnter: (to, from, next) =>{
            if(getCookie()) next({name:'main'})
            else next()
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LogIn,
        beforeEnter: (to, from, next) =>{
            if(getCookie()) next({name:'main'})
            else next()
        }
    },
    {
        path: '/Main', 
        name: 'main',
        component: Main,
        beforeEnter: (to, from, next) =>{
            if(getCookie()) next()
            else next({name:'login'})
        }
    },
    {
        path: '/Home', 
        name: 'home',
        component: Home,
        beforeEnter: (to, from, next) =>{
            if(getCookie()) next()
            else next({name:'login'})
        }
    },
    {
        path: '/Help', 
        name: 'help',
        component: Help,
        beforeEnter: (to, from, next) =>{
            if(getCookie()) next()
            else next({name:'login'})
        }
    }
]

const router = createRouter({
    routes,
    history: createWebHistory()
})
export default router;