<template lang="pug">
router-view
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="sass">
</style>
