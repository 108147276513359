<template lang="pug">
.reply-box.input-text(:class="{ 'reply-box-select': seletPageAll }")
  .reply-box__reply
    input(
      type="text",
      name="placeholderText",
      placeholder="Подсказка",
      :value="name.placeholder",
      @input="setPlaceholder($event, idx)"
    )
</template>

<script>
export default {
  name: "input-text",
  props: ["name", "idx", "setPlaceholder", "noNegativeNumber", "seletPageAll"],
  setup() {},
};
</script>

<style lang="sass" scoped>
.input-text
  .reply-box__reply
    width: 100%
    border-radius: 4px
    position: relative
    margin-bottom: 5px
</style>