<template lang="pug">
section.quiz
  h3 Выибирете страницу
  .button-quiz__page-box
    .button-quiz__page(@click="addPage('checkbox-img')")
      img(src="@/../public/img/check-img.svg")
      p Несколько вариантов ответа с фото
    .button-quiz__page(@click="addPage('radio-img')")
      img(src="@/../public/img/radio-img.svg")
      p Один вариант ответа с фото
    .button-quiz__page(@click="addPage('checkbox')")
      img(src="@/../public/img/check.svg")
      p Несколько вариантов ответа
    .button-quiz__page(@click="addPage('radio')")
      img(src="@/../public/img/radio.svg")
      p Один вариант <br> ответа
    .button-quiz__page(@click="addPage('text-check')")
      img(src="@/../public/img/text-check.svg")
      p Текстовое поле с чекбоксом
    .button-quiz__page(@click="addPage('input-text')")
      img(src="@/../public/img/text.svg")
      p Текстовое <br> поле
    .button-quiz__page(@click="addPage('range-check')")
      img(src="@/../public/img/range-check.svg")
      p Ползунок с <br>чекбоксом
    .button-quiz__page(@click="addPage('range')")
      img(src="@/../public/img/range.svg")
      p Ползунок

  .select-reply-all(@click="selectReplyAll", v-if="CHECK_IMG.length > 0")
    img(src="@/../public/img/select.svg", v-if="!seletPageAll")
    img(src="@/../public/img/select-up.svg", v-if="seletPageAll")
    p(v-if="!seletPageAll") свернуть все страницы
    p(v-if="seletPageAll") развернуть все страницы
  .quiz-page-container(
    @drop="onDrop($event)",
    @dragstart="dragStart($event)",
    @dragend="dragEnd($event)",
    @dragover="dragOver($event)",
    @dragenter.prevent
  )
    template(v-for="(item, idx) in CHECK_IMG")
      .quiz-page(draggable="true", @dragstart="onDragStart($event)", :id="idx")
        .quiz-page__close(@click="delPage($event, idx)")
        .num-gage {{ idx + 1 }}
        .avatar
          .maket-modal-avatar
            img(:src="avatarImgSrc")
          textarea.avatar-text(
            type="text",
            name="avatartext",
            placeholder="Текст подсказка",
            :value="item.avatarText",
            @input="inputAvatar($event, idx)"
          )
        input.question-text(
          type="text",
          name="question",
          placeholder="Вопрос",
          :value="item.question",
          @input="inputQuestion($event, idx)"
        )
        template(v-if="item.form === 'checkbox-img'")
          checkbox-img(
            :name="item.reply",
            :idx="idx",
            :addReply="addReply",
            :noNegativeNumber="noNegativeNumber",
            :setQuestion="setQuestion",
            :delReply="delReply",
            :seletPageAll="seletPageAll",
            :getFileInput="getFileInput",
            :CHECK_IMG="CHECK_IMG"
          )
        template(v-if="item.form === 'radio-img'")
          radio-img(
            :name="item.reply",
            :idx="idx",
            :addReply="addReply",
            :noNegativeNumber="noNegativeNumber",
            :setQuestion="setQuestion",
            :delReply="delReply",
            :seletPageAll="seletPageAll",
            :getFileInput="getFileInput",
            :CHECK_IMG="CHECK_IMG"
          )
        template(v-if="item.form === 'checkbox'")
          checkbox(
            :name="item.reply",
            :idx="idx",
            :addReply="addReply",
            :noNegativeNumber="noNegativeNumber",
            :setQuestion="setQuestion",
            :delReply="delReply",
            :seletPageAll="seletPageAll"
          )
        template(v-if="item.form === 'radio'")
          radio(
            :name="item.reply",
            :idx="idx",
            :addReply="addReply",
            :noNegativeNumber="noNegativeNumber",
            :setQuestion="setQuestion",
            :delReply="delReply",
            :seletPageAll="seletPageAll"
          )
        template(v-if="item.form === 'text-check'")
          text-check(
            :name="item",
            :idx="idx",
            :setPlaceholder="setPlaceholder",
            :noNegativeNumber="noNegativeNumber",
            :setQuestion="setQuestion",
            :seletPageAll="seletPageAll"
          )
        template(v-if="item.form === 'input-text'")
          input-text(
            :name="item",
            :idx="idx",
            :seletPageAll="seletPageAll",
            :setPlaceholder="setPlaceholder"
          )
        template(v-if="item.form === 'range-check'")
          range-check(
            :name="item",
            :idx="idx",
            :noNegativeNumber="noNegativeNumber",
            :setQuestion="setQuestion",
            :seletPageAll="seletPageAll",
            :setRangeMin="setRangeMin",
            :setRangeMax="setRangeMax",
            :setRangeStep="setRangeStep",
            :setRangeEdenica="setRangeEdenica"
          )
        template(v-if="item.form === 'range'")
          range(
            :name="item",
            :idx="idx",
            :seletPageAll="seletPageAll",
            :setRangeMin="setRangeMin",
            :setRangeMax="setRangeMax",
            :setRangeStep="setRangeStep",
            :setRangeEdenica="setRangeEdenica"
          )
        .reply-box__nav(:class="{ 'reply-box__nav-select': seletPageAll }")
          .nav-line
          img(src="@/../public/img/button-nav-quiz.svg")

  last-form(:idx="idxLastform", :avatarImg="avatarImgSrc")
  .save-quiz-box(v-if="CHECK_IMG.length > 0 && $store.state.siteName")
    my-button(@click="saveQuiz") Сохранить
    .script-text-box(v-if="createScript")
      .copy-script(@click="copyBuffer") copy
      .script-text(readonly) {{ createScript }}
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import {
  distrObject,
  getLocal,
  Toast,
} from "@/components/function/distrobject";
export default {
  setup() {
    let CHECK_IMG = ref([]);
    const store = useStore();
    let dropEL = ref("");
    let seletPageAll = ref(false);
    let saveCode = ref([]);
    let idxLastform = ref([]);
    let createScript = ref("");

    function copyBuffer() {
      const contentHolder = document.querySelector(".script-text");
      // Нам понадобится объект диапазона и выделения
      var range = document.createRange(),
        selection = window.getSelection();

      // Очищаем выделение от предыдущей информации
      selection.removeAllRanges();

      // Выделяем весь контент в параграфе contentHolder.
      range.selectNodeContents(contentHolder);

      // Добавляем диапазон в выделение.
      selection.addRange(range);

      // Копируем выделенное в буфер обмена.
      document.execCommand("copy");

      // Можем очистить выделение.
      selection.removeAllRanges();
      Toast.fire({
        icon: "info",
        title: "Скопировано в буфер обмена!",
      });
    }
    function resizeBase64Img(base64, newWidth) {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        let img = document.createElement("img");
        img.src = base64;
        img.onload = function () {
          const scaleFactor = newWidth / img.width;
          canvas.width = newWidth;
          canvas.height = img.height * scaleFactor;
          context.drawImage(img, 0, 0, newWidth, img.height * scaleFactor);
          resolve(canvas.toDataURL("image/jpeg", 0.8));
        };
      });
    }

    function getFileInput(e, idx, replayID) {
      const reader = new FileReader();
      reader.onload = (ev) => {
        resizeBase64Img(ev.target.result, 247).then((result) => {
          CHECK_IMG.value[idx].reply[replayID].img = result;
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }

    function setLocalStorage() {
      localStorage.setItem("pages", JSON.stringify(CHECK_IMG.value));
    }

    onMounted(loadLocalStorage);

    function loadLocalStorage() {
      if (localStorage.getItem("pages")) {
        CHECK_IMG.value = getLocal("pages");
      } else {
        localStorage.setItem("pages", JSON.stringify(CHECK_IMG.value));
      }
    }
    function pageNumbWrite() {
      if (CHECK_IMG.value.length == 0) {
        idxLastform.value = 1;
        return;
      }
      for (let i = 0; i < CHECK_IMG.value.length; i++) {
        let n = i + 1;
        CHECK_IMG.value[i].page = n;
        idxLastform.value = n + 1;
      }
    }
    watch(
      () => store.state.siteName,
      (e) => {
        const siteNameNoDot = e.replace(".", "");
        createScript.value = `<script type="text/javascript" src="https://rquiz.mediarama.by/?id=${siteNameNoDot}"><\/script>`;
      }
    );
    watch(
      () => [...CHECK_IMG.value],
      () => {
        pageNumbWrite();
        setLocalStorage();
      },
      { deep: true }
    );
    watch(
      () => store.state.allQuiz,
      () => {
        CHECK_IMG.value = getLocal("pages");
      }
    );

    function inputQuestion(e, idx) {
      CHECK_IMG.value[idx].question = e.target.value;
    }
    function inputAvatar(e, idx) {
      CHECK_IMG.value[idx].avatarText = e.target.value;
    }
    function addPage(form) {
      const obj = {
        page: "",
        form: form,
        question: "",
        avatarText: "",
        min: "",
        max: "",
        step: "",
        edenica: "",
        placeholder: "",

        reply: [
          {
            img: "",
            gopage: "",
            replytext: "",
            addbut: true,
          },
        ],
      };
      CHECK_IMG.value.push(obj);
    }
    // проверяем какая страница открыта

    function delPage(e, idx) {
      CHECK_IMG.value.splice(idx, 1);
    }

    function addReply(e, idx) {
      const replay = {
        img: "",
        gopage: "",
        replytext: "",
        addbut: "",
      };

      CHECK_IMG.value[idx].reply.push(replay);
      const indexLast = CHECK_IMG.value[idx].reply;
      indexLast.forEach((element) => {
        element.addbut = "";
        indexLast[indexLast.length - 1].addbut = true;
      });
    }

    function delReply(e, idx, replayID) {
      CHECK_IMG.value[idx].reply.splice(replayID, 1);
    }
    function noNegativeNumber(e, idx, replayID) {
      if (e.target.value < 1) e.target.value = "";
      if (e.target.value > CHECK_IMG.value.length)
        e.target.value = +CHECK_IMG.value.length + 1;
      if (replayID)
        CHECK_IMG.value[idx].reply[replayID].gopage = e.target.value;
      if (!replayID) CHECK_IMG.value[idx].reply[0].gopage = e.target.value;
    }
    function setQuestion(e, idx, replayID) {
      if (replayID)
        CHECK_IMG.value[idx].reply[replayID].replytext = e.target.value;
      if (!replayID) CHECK_IMG.value[idx].reply[0].replytext = e.target.value;
    }
    function setPlaceholder(e, idx) {
      CHECK_IMG.value[idx].placeholder = e.target.value;
    }
    function setRangeMin(e, idx) {
      if (e.target.value < 0) e.target.value = 0;
      CHECK_IMG.value[idx].min = e.target.value;
    }
    function setRangeMax(e, idx) {
      if (e.target.value < 0) e.target.value = 0;
      CHECK_IMG.value[idx].max = e.target.value;
    }
    function setRangeStep(e, idx) {
      if (e.target.value < 0) e.target.value = 0;
      CHECK_IMG.value[idx].step = e.target.value;
    }
    function setRangeEdenica(e, idx) {
      CHECK_IMG.value[idx].edenica = e.target.value;
    }
    function selectReplyAll() {
      seletPageAll.value
        ? (seletPageAll.value = false)
        : (seletPageAll.value = true);
    }

    function onDragStart(e) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.dropAllowed = "move";
      e.dataTransfer.setData("itemId", e.target.id.toString());
      if (e.target.classList.contains("reply-box__reply"))
        dropEL.value = "reply";
      if (e.target.classList.contains("quiz-page")) dropEL.value = "page";
    }

    function onDrop(e) {
      const itemId = parseInt(e.dataTransfer.getData("itemId")); // ID Элемент который схватил
      if (dropEL.value == "reply") {
        if (
          e.target.offsetParent.classList.contains("reply-box__reply") ||
          e.target.offsetParent.offsetParent.classList.contains(
            "reply-box__reply"
          )
        ) {
          const IDpage = parseInt(
            e.target.parentElement.closest(".quiz-page").id
          );
          const ID = parseInt(e.target.closest(".reply-box__reply").id); // ID Элемент который меняем
          const allEl = CHECK_IMG.value[IDpage].reply[itemId]; // ВЕСЬ Элемент который схватил
          const allElArr = CHECK_IMG.value[IDpage].reply[ID]; // ВЕСЬ элемент который меняем
          CHECK_IMG.value[IDpage].reply.splice(ID, 1, allEl);
          CHECK_IMG.value[IDpage].reply.splice(itemId, 1, allElArr);

          const indexLast = CHECK_IMG.value[IDpage].reply;
          indexLast.forEach((element) => {
            element.addbut = "";
            indexLast[indexLast.length - 1].addbut = true;
          });
        }
      }

      if (dropEL.value == "page") {
        const ID = parseInt(e.target.closest(".quiz-page").id); // ID Элемент который меняем
        const allEl = CHECK_IMG.value[itemId]; // ВЕСЬ Элемент который схватил
        const allElArr = CHECK_IMG.value[ID]; // ВЕСЬ элемент который меняем
        CHECK_IMG.value.splice(ID, 1, allEl);
        CHECK_IMG.value.splice(itemId, 1, allElArr);
      }
    }
    function dragStart(e) {
      e.target.classList.add(`elem-selected`);
    }
    function dragEnd(e) {
      e.target.classList.remove(`elem-selected`);
      const activeElement = document.querySelectorAll(`.quiz-page-active`);
      activeElement.forEach((el) => {
        if (el.classList.contains("quiz-page-active"))
          el.classList.remove("quiz-page-active");
      });
      const activeElement2 = document.querySelectorAll(
        `.reply-box__reply-active`
      );
      activeElement2.forEach((el) => {
        if (el.classList.contains("reply-box__reply-active"))
          el.classList.remove("reply-box__reply-active");
      });
      dropEL.value = "";
    }
    function dragOver(e) {
      e.preventDefault();
      if (dropEL.value == "") return;
      if (
        e.target.classList.contains("quiz-page") &&
        dropEL.value !== "reply"
      ) {
        e.target.classList.add("quiz-page-active");
      }
      const activeElement = document.querySelectorAll(`.quiz-page-active`);
      activeElement.forEach((element) => {
        if (element !== e.target.closest(".quiz-page"))
          element.classList.remove("quiz-page-active");
      });

      if (
        e.target.offsetParent.classList.contains("reply-box__reply") &&
        dropEL.value !== "page"
      ) {
        if (!e.target.offsetParent.classList.contains("elem-selected")) {
          e.target.offsetParent.classList.add("reply-box__reply-active");
        }
      }
      const activeElement2 = document.querySelectorAll(
        `.reply-box__reply-active`
      );
      activeElement2.forEach((element) => {
        if (element !== e.target.offsetParent)
          element.classList.remove("reply-box__reply-active");
      });
    }

    function saveQuiz() {
      saveCode.value = distrObject();

      if (!store.state.siteName) {
        Toast.fire({
          icon: "error",
          title: "В общих настройках не заполнено поле 'Название сайта'",
        });
        return;
      }
      if (!store.state.clientEmail) {
        Toast.fire({
          icon: "error",
          title: "В общих настройках не заполнено поле 'Email'",
        });
        return;
      }
      const getCookie = () => {
        const getCook = document.cookie;
        const splitCook = getCook.split(";");
        const out = splitCook.find(
          (elm) => elm.split("=")[0].trim() == "m-quiz-auth"
        );
        if (out) return out.split("=")[1];
      };
      const formData = new FormData();
      formData.append("quiz", JSON.stringify(saveCode.value));
      formData.append("email", getCookie());
      fetch("https://rquiz.mediarama.by/createQuiz.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            Toast.fire({
              icon: "error",
              title: "Ошибка отпраки!",
            });
            return;
          }
          Toast.fire({
            icon: "success",
            title: "Квиз Сохранен!",
          });

          store.dispatch("getQuizName");
          store.commit("UPDATE_ALLQUIZSAVE", distrObject());
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return {
      idxLastform,
      avatarImgSrc: computed(() => {
        return store.state.avatarUrl;
      }),

      saveQuiz,
      copyBuffer,
      createScript,
      saveCode,
      setRangeMin,
      setRangeMax,
      setRangeStep,
      setRangeEdenica,
      setPlaceholder,
      getFileInput,
      setLocalStorage,
      dropEL,
      onDragStart,
      dragStart,
      dragEnd,
      dragOver,
      onDrop,
      CHECK_IMG,
      seletPageAll,
      selectReplyAll,
      delPage,
      delReply,
      setQuestion,
      inputQuestion,
      inputAvatar,
      addPage,
      addReply,
      noNegativeNumber,
    };
  },
};
</script>

<style lang="sass">
.quiz
  .save-quiz-box
    margin: 0 auto
    position: sticky
    bottom: 0
    z-index: 10
    background: #fff
    max-width: 1100px
    width: 100%
    left: 0
    right: 0
    display: flex
    flex-wrap: wrap
    padding: 25px 15px 8px 15px
    align-items: center
    justify-content: center
    box-shadow: 0px -4px 50px rgba(0, 0, 0, 0.15)
    border-radius: 6px 6px 0px 0px
    button
      max-width: 160px
      width: 100%
      margin: 0 10px
    .script-text-box
      position: relative
      max-width: 750px
      width: 100%
      .script-text
        width: 100%
        padding: 15px
        margin-right: 10px
        text-align: center
        font-size: 16px
        background: #f3f3f3
        border: 1px solid #c6c6c6
        border-radius: 4px
        white-space: nowrap
        overflow-x: auto
    .copy-script
      position: absolute
      background: #f3f3f3
      padding: 3px 8px
      border: 1px solid #c6c6c6
      border-radius: 4px
      right: 0
      top: -12px
      cursor: pointer
  .button-quiz__page-box
    display: flex
    overflow-x: auto
  .button-quiz__page
    border: 1px solid #BABABA
    border-radius: 4px
    min-width: 115px
    max-width: 115px
    overflow: hidden
    cursor: pointer
    box-sizing: border-box
    transition: .3s
    will-change: transform
    margin-right: 10px
    display: flex
    flex-direction: column
    justify-content: space-between
    &:active
      transform: scale(0.98)
    &:hover
      border: 1px solid red

    img
      margin: 10px auto
    p
      font-weight: 300
      font-size: 11px
      line-height: 13px
      text-align: center
      color: #FFFFFF
      background: #606060
      padding: 8px 2px
      margin: 0
      height: 40px
  .select-reply-all
    border: 1px solid #CECECE
    max-width: 190px
    position: relative
    margin: 15px 0 10px
    border-radius: 4px
    cursor: pointer
    p
      margin: 3px 0
      padding: 0 0 0 18px
      text-align: center
      font-size: 14px
    img
      width: 15px
      margin-right: 0px
      margin: auto
      position: absolute
      top: 0
      bottom: 0
      left: 5px
  .quiz-page-container
    margin-top: 10px
    // box-shadow: 2px 2px 20px rgba(0, 0, 0, .2) inset
    // background: whitesmoke
    padding: 15px 0
    border-radius: 4px
    border: 1px solid #cecece
    .quiz-page
      cursor: move
      margin: 10px auto
      max-width: 805px
      width: 100%
      background: none
      border: 1px solid #CECECE
      box-sizing: border-box
      border-radius: 4px
      padding: 15px
      position: relative
      box-shadow: 2px 4px 15px rgba(0, 0, 0, .2)
      .avatar
        display: flex
        margin-top: 15px
        .maket-modal-avatar
          border-radius: 50px
          border: 1px solid #e3e3e3
          width: 50px
          height: 50px
          margin-right: 8px
          position: relative
          &::before
            content: ""
            position: absolute
            bottom: -3px
            left: 33px
            width: 12px
            height: 12px
            background: #11d700
            right: 0
            border-radius: 10px
            margin: auto
            border: 1px solid #ffffff

          img
            border-radius: 50px
            width: 50px
            height: 50px
            object-fit: cover

        textarea
          width: 100%
          height: 50px
          padding: 5px
          font-size: 14px
      .quiz-page__close
        width: 30px
        height: 30px
        position: absolute
        top: 0px
        right: 0px
        cursor: pointer
        &::before,&::after
          content: ""
          height: 2px
          background: #000
          width: 25px
          position: absolute
          left: 0
          right: 0
          top: 0
          bottom: 0
          transform: rotate(45deg)
          margin: auto
        &::after
          transform: rotate(-45deg)

      .num-gage
        border: 1px solid #cacaca
        border-color: transparent #cacaca #cacaca transparent
        min-width: 25px
        text-align: center
        padding: 3px
        border-radius: 4px 0 4px 0
        position: absolute
        top: 0
        left: 0
        font-size: 12px
      [name="question"]
        margin: 5px 0 10px !important
      .reply-box
        height: 285px
        display: flex
        flex-wrap: wrap
        align-items: flex-start
        overflow: auto
        transition: .3s
      .reply-box__reply-active
        background: #E0E0E0
      .delReply
        cursor: pointer
      .reply-add-page
        width: 30px
        height: 30px
        position: absolute
        background: #C4C4C4
        box-sizing: border-box
        border-radius: 4px
        cursor: pointer
        top: 0px
        right: 0
        &::before,&::after
          content: ""
          height: 1px
          background: #000
          width: 15px
          position: absolute
          left: 0
          right: 0
          top: 0
          bottom: 0
          margin: auto
        &::after
          transform: rotate(90deg)
    .quiz-page-active
      background: #E0E0E0
      border-color: #C1C1C1
    .elem-selected
      opacity: .3
    .reply-box__nav
      bottom: 0
      padding: 8px 0 0
      display: flex
      align-items: center
      justify-content: space-between
      overflow: hidden
      transition: .3s
      .nav-line
        max-width: 590px
        width: 100%
        height: 4px
        background: #D3D3D3
        overflow: hidden
        position: relative
        z-index: 2
        &::before
          content: ""
          width: 30%
          height: 4px
          background: #239CFC
          position: absolute
          z-index: 1
    .reply-box__nav-select
      height: 0
      padding: 0px 0 0
  .reply-box-select
    height: 0px !important
  .last-page
    margin-top: 15px
</style>