import { createStore } from 'vuex'
import router from '@/router/router'
import { Toast,monthName } from "@/components/function/distrobject";
export default createStore({
  
  state () {
    return{
    // isAuth: false,
    date:"",
    siteName:"",
    clientEmail:"",
    avatarUrl:"https://ramabot.mediarama.by/client/img/m-1.jpg",
    allQuiz:[],
    dateCreateQuiz:"",
    reload:"",
    getAvatars:{ imgMan: [], imgWoman: [] },
    home:"",
    allSiteName:[],
    analytics:[],
    clientele:[],
  }
  },
  getters : {
    // getIsAuth(state){
    //   return state.isAuth
    // },
    // allSiteName(state){
    //   const arr = [];
    //   for (const key in state.allQuiz) {
    //     arr.push(state.allQuiz[key].options.siteName)
    //   }
    //   return arr;
    // },
    clientEmail() {
      const getCook = document.cookie;
      const splitCook = getCook.split(";");
      const out = splitCook.find(
        (elm) => elm.split("=")[0].trim() == "m-quiz-auth"
      );
      if (out) return out.split("=")[1];
    }
  },
  mutations : {
    UPDATE_AVATARURL(state,url){
      state.avatarUrl = url
    },
    UPDATE_SITENAME(state,q){
      state.siteName = q
    },
    UPDATE_CLIENTNAME(state,q){
      state.clientEmail = q
    },
    CLEAR_STATE(state){
      state.date = "",
      state.siteName = "",
      state.clientEmail = "",
      state.allQuiz = [],
      state.dateCreateQuiz = "",
      state.reload = "",
      state.home = "",
      state.allSiteName = [],
      state.analytics = [],
      state.clientele = [],
      state.allSiteName = [];
    },
    // UPDATE_COOKIE_SEND(state){
    //   if(!state.isAuth) state.isAuth = true
    // },
    UPDATE_HOME(state,result){
      state.home = result;
    },
    UPDATE_AVATAR(state,avatars){
      for (let key in avatars) {
        if (avatars[key]["man"])
        state.getAvatars.imgMan.push(avatars[key]["man"]);
        if (avatars[key]["wooman"])
        state.getAvatars.imgWoman.push(avatars[key]["wooman"]);
      }
    },
    UPDATE_ALLQUIZNAME(state,result){
      state.allSiteName = result;
    },
    UPDATE_ALLQUIZ(state,result){
      let obj = { 
          ...JSON.parse(result.quiz)
      }
      state.allQuiz = obj;
      state.date = result.date;
    },
    UPDATE_ALLQUIZSAVE(state,result){
      state.allQuiz = result;
    },
    UPDATE_ALLQUIZRESET(state){
      state.allQuiz = [];
      state.date = "";
    },
    DATE_CREATE_QUIZ(state,result){
      state.dateCreateQuiz = result
    },
    RELOAD(state,result){
      state.reload = result
    },
    RELOAD_ALLQUIZ(state){
      state.allQuiz = [];
    },
    UPDATE_ANALYST(state,data){
      state.analytics = data;
    },
    UPDATE_CLIENTELE(state,data){
      state.clientele = data;
    }
  },

  actions:{
    getQuizName({ commit, getters }) {
      const formData = new FormData();
      formData.append("email", getters.clientEmail);
      formData.append("siteName", true);
      fetch("https://rquiz.mediarama.by/getQuiz.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            Toast.fire({
              icon: "error",
              title: "Ошибка!",
            });
            document.cookie = `m-quiz-auth=; max-age=-1`;
            router.push("/");
            return
          }
          const arrName = [] 
            for (const key in result) arrName.push(result[key])
            commit('UPDATE_ALLQUIZNAME',arrName);
        }).catch((error) => {
          Toast.fire({
            icon: "error",
            title: "Ошибка!",
          });
          document.cookie = `m-quiz-auth=; max-age=-1`;
          router.push("/");
          console.log(error);
        });
    },
    getQuiz({ commit, getters, state }) {
      const formData = new FormData();
      formData.append("email", getters.clientEmail);
      formData.append("site", state.reload);
      fetch("https://rquiz.mediarama.by/getQuiz.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result !== "false") {
            commit('RELOAD_ALLQUIZ');
            for (const key in result) commit('UPDATE_ALLQUIZ',result[key]);
          }
        }).catch((error) => {
          console.log(error);
        });
    },
    getAvatarImg({ commit }) {
      fetch("https://ramabot.mediarama.by/getimg.php")
        .then((response) => response.json())
        .then((avatars) => {
          commit('UPDATE_AVATAR',avatars);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMod({ commit, getters }) {
      const formData = new FormData();
      formData.append("email", getters.clientEmail);
      fetch("https://rquiz.mediarama.by/getUser.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            alert("Ошибка при получении данных!");
            return;
          }
          commit('UPDATE_HOME',result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAnalyst({ commit, state },data) {
      const formData = new FormData();
      formData.append("siteName", state.siteName);
      if (data) {
        formData.append("months", JSON.stringify(data.months));
        formData.append("years", JSON.stringify(data.years));
      } else {
        const newDate = new Date();
        formData.append(
          "months",
          JSON.stringify([String(newDate.getMonth() + 1)])
        );
        formData.append(
          "years",
          JSON.stringify([String(newDate.getFullYear())])
        );
      }

      fetch("https://rquiz.mediarama.by/getAnalyst.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            state.analytics = [];
            let allObj = [];
            for (const key in result) {
              allObj.push(result[key]);
            }
            commit("UPDATE_ANALYST",allObj);
          }else{
            commit("UPDATE_ANALYST",'');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClientele({ commit, state },data) {
      const formData = new FormData();
      formData.append("siteName", state.siteName);
      if (data) {
        formData.append("months", JSON.stringify(data.months));
        formData.append("years", JSON.stringify(data.years));
      } else {
        const newDate = new Date();
        formData.append(
          "months",
          JSON.stringify([String(newDate.getMonth() + 1)])
        );
        formData.append(
          "years",
          JSON.stringify([String(newDate.getFullYear())])
        );
      }
      fetch("https://rquiz.mediarama.by/getClientele.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            state.clientele = [];
            let allObj = [];
            for (const key in result) {
              const newObj = {
                Имя: result[key].clientname,
                Телефон: result[key].clientphone,
                Месяц: monthName(result[key].month),
                Год: result[key].year,
              };
              allObj.push(newObj);
            }
            commit("UPDATE_CLIENTELE",allObj);
          }else{
            commit("UPDATE_CLIENTELE",'');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

})
