<template lang="pug">
.reply-box.range(:class="{ 'reply-box-select': seletPageAll }")
  .reply-box__reply
    img(src="@/../public/img/polzunok.svg")
    .input-box
      label 
        p минимальное занчение
        input(
          type="number",
          name="min",
          placeholder="min",
          :value="name.min",
          @input="setRangeMin($event, idx)"
        )
      label
        p максимальное занчение
        input(
          type="number",
          name="max",
          placeholder="max",
          :value="name.max",
          @input="setRangeMax($event, idx)"
        )
      label
        p шаг
        input(
          type="number",
          name="step",
          placeholder="step",
          :value="name.step",
          @input="setRangeStep($event, idx)"
        )
      label
        p единица измерения
        input(
          type="text",
          name="edenica",
          placeholder="руб",
          :value="name.edenica",
          @input="setRangeEdenica($event, idx)"
        )
</template>

<script>
export default {
  name: "range",
  props: [
    "name",
    "idx",
    "setPlaceholder",
    "seletPageAll",
    "setRangeMin",
    "setRangeMax",
    "setRangeStep",
    "setRangeEdenica",
  ],
  setup() {},
};
</script>

<style lang="sass" scoped>
.range
  .reply-box__reply
    width: 100%
    border-radius: 4px
    position: relative
    margin-bottom: 5px
    .input-box
      display: flex
      flex-wrap: wrap
      label
        margin-right: 10px
        max-width: 140px
        margin-top: 10px
</style>