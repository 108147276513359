<template lang="pug">
section.avatar
  h3 Укажите имя аватара
  .row
    .col-sm-6.col-md-6.col-lg-4
      label 
        p Укажите имя аватара
        input(
          v-model="avatar.avatarName",
          name="avatarName",
          type="text",
          placeholder="Имя"
        )
  h3 Выберите аватар
  .row
    .col-sm-6.col-md-6.col-lg-6
      p(style="margin-top: 0") Мужчины
      .img-avatar-container
        .img-avatar-box(
          v-for="(itemimg, idx) in getAvatars.imgMan",
          :class="{ activeAvatar: idx == 0 }",
          @click="avatarClick('m-' + idx)",
          :id="'m-' + idx",
          :data-src="'https://ramabot.mediarama.by/client/img/' + itemimg"
        )
          img(:src="'https://ramabot.mediarama.by/client/img/' + itemimg")
    .col-sm-6.col-md-6.col-lg-6
      p(style="margin-top: 0") Женщины
      .img-avatar-container
        .img-avatar-box(
          v-for="(itemimg, idx) in getAvatars.imgWoman",
          @click="avatarClick('w-' + idx)",
          :id="'w-' + idx",
          :data-src="'https://ramabot.mediarama.by/client/img/' + itemimg"
        )
          img(:src="'https://ramabot.mediarama.by/client/img/' + itemimg")
  h3 Свой аватар
  .row
    .col-sm-4.col-md-4.col-lg-4
      .label-box.fileAvatar
        .avatar-img-remove(
          v-if="avatarImgSrc !== '../img/img-file.svg'",
          @click="delUserAvatar"
        ) &#10006;
        label.file
          img(:src="avatarImgSrc") 
          input(
            @change="fileModal",
            name="fileAvatar",
            type="file",
            accept=".png,.jpg,.jpeg,.gif,.svg"
          )
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
export default {
  emits: ["avatarUrl"],
  setup(_, { emit }) {
    const store = useStore();
    const avatar = ref({
      avatarName: "Александр",
      avatarUrl: "https://ramabot.mediarama.by/client/img/m-1.jpg",
    });
    const getAvatars = computed(() => {
      return store.state.getAvatars;
    });
    const avatarImgSrc = ref("../img/img-file.svg");

    onMounted(() => {
      if (store.state.getAvatars.imgMan.length == 0) {
        store.dispatch("getAvatarImg");
      }
      loadLocalStorage();
    });

    function loadLocalStorage() {
      if (localStorage.getItem("avatar")) {
        avatar.value = JSON.parse(localStorage.getItem("avatar"));
      } else {
        localStorage.setItem("avatar", JSON.stringify(avatar.value));
      }
    }

    //------------------------------------
    function delUserAvatar() {
      avatarImgSrc.value = "../img/img-file.svg";
      document.querySelector("[name='fileAvatar']").value = "";
      avatar.value.avatarUrl =
        "https://ramabot.mediarama.by/client/img/m-1.jpg";
      activeAvatar();
      store.commit("UPDATE_AVATARURL", "");
    }

    watch(
      () => avatar.value,
      (url) => {
        store.commit("UPDATE_AVATARURL", url.avatarUrl);
        localStorage.setItem("avatar", JSON.stringify(avatar.value));
        emit("avatarUrl", avatar.value.avatarUrl);
        if (url.avatarUrl) {
          url.avatarUrl.search("base64") != -1
            ? (avatarImgSrc.value = url.avatarUrl)
            : (avatarImgSrc.value = "../img/img-file.svg");
        }
      },
      { deep: true }
    );
    function getLocal(key) {
      return JSON.parse(localStorage.getItem(key));
    }
    watch(
      () => store.state.allQuiz,
      () => {
        avatar.value = getLocal("avatar");
      }
    );

    // Получаем с сервера аватаров
    // function getAvatarImg() {
    //   fetch("https://ramabot.mediarama.by/getimg.php")
    //     .then((response) => response.json())
    //     .then((commits) => {
    //       for (let key in commits) {
    //         if (commits[key]["man"])
    //           getAvatars.value.imgMan.push(commits[key]["man"]);
    //         if (commits[key]["wooman"])
    //           getAvatars.value.imgWoman.push(commits[key]["wooman"]);
    //       }
    //     })
    //     .then(() => {
    //       activeAvatar();
    //     });
    // }
    //------------------------------------
    function activeAvatar() {
      if (avatarImgSrc.value !== "../img/img-file.svg") return;
      const thisActivEl = document.querySelector("#m-0");
      thisActivEl.classList.add("activeAvatar");
      avatar.value.avatarUrl = thisActivEl.getAttribute("data-src");
    }

    function avatarClick(e) {
      if (avatarImgSrc.value !== "../img/img-file.svg") return;
      const thisActivEl = document.querySelector("#" + e);
      avatar.value.avatarUrl = thisActivEl.getAttribute("data-src");
    }

    watch(
      () => avatar.value.avatarUrl,
      (e) => {
        const el = document.querySelectorAll(".img-avatar-box");
        const otherActivEl = document.querySelectorAll(".activeAvatar");
        el.forEach((q) => {
          if (q.getAttribute("data-src") == e) q.classList.add("activeAvatar");
          else q.classList.remove("activeAvatar");
        });
        // otherActivEl.forEach((e) => e.classList.remove("activeAvatar"));
      }
    );

    function resizeBase64Img(base64, newWidth) {
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        let img = document.createElement("img");
        img.src = base64;
        img.onload = function () {
          const scaleFactor = newWidth / img.width;
          canvas.width = newWidth;
          canvas.height = img.height * scaleFactor;
          context.fillStyle = "#fff";
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, 0, 0, newWidth, img.height * scaleFactor);
          resolve(canvas.toDataURL("image/jpeg", 0.8));
        };
      });
    }

    function fileModal(e) {
      const reader = new FileReader();
      reader.onload = (ev) => {
        resizeBase64Img(ev.target.result, 100).then((result) => {
          avatarImgSrc.value = result;
          avatar.value.avatarUrl = result;
        });
      };
      reader.readAsDataURL(e.target.files[0]);
      const el = document.querySelectorAll(".img-avatar-box");
      el.forEach((e) => {
        e.classList.remove("activeAvatar");
      });
    }
    return {
      loadLocalStorage,
      delUserAvatar,
      fileModal,
      avatarClick,
      getAvatars,
      avatar,
      // getAvatarImg,
      avatarImgSrc,
    };
  },
};
</script>

<style lang="sass" scoped>
.avatar
  .img-avatar-container
    display: flex
    flex-wrap: wrap
    .img-avatar-box
      margin: 4px
      width: 55px
      height: 55px
      border-radius: 50px
      overflow: hidden
      border: 2px solid #a5a5a5
      cursor: pointer
      img
        object-fit: cover
        width: 100%
    .activeAvatar
      border: 2px solid #ff0000
      position: relative
      &::after
        content: ''
        width: 100%
        height: 100%
        background: rgba(200,0,0,.5)
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0
        margin: auto
        z-index: 1
  .label-box
    width: 88px
    height: 88px
    position: relative
    .avatar-img-remove
      color: #ff0000
      font-size: 24px
      position: absolute
      top: -14px
      right: -15px
      cursor: pointer

    .file
      display: flex
      align-items: center
      justify-content: space-between
      max-width: 88px
      max-height: 88px
      cursor: pointer
      position: relative
      overflow: hidden
      border-radius: 50px
      img
        width: 100%
    [type="file"]
      display: none
</style>